import { useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Status } from "../../../GlobalComponents";
import { formatCurrency } from "../../../util";
import styled from "@emotion/styled";
import { Title } from "../components";
import CollectionsEventModal from "./CollectionEventModal";

const columns: GridColDef[] = [
  {
    field: "date", headerName: "Date of Outcome", width: 140,
  },
  {
    field: "email", headerName: "Agent", width: 200,
  },
  {
    field: "collection_outcomes",
    headerName: "Outcome",
    width: 180,
    // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <Status status={params.value} textStyle={{fontSize: 12}} />
    ),
  },
  { field: "collection_suboutcomes", headerName: "Sub Outcome", width: 140 },
  { field: "contact_method", headerName: "Contact Method", width: 140 },
  { field: "ms_time_to_call", headerName: "Time to Call", width: 140 },
  { field: "ms_collections_amt_add_pay", headerName: "Amount Collected", width: 200 },
  {
    field: "promise_to_pay_date",
    headerName: "Promise Date",
    width: 160,
    // @ts-ignore
    // renderCell: (params: GridValueGetterParams) => (
    //   <Status status={params.value} />
    // ),
  },
  { field: "ms_collections_reas_miss_pay", headerName: "Reason for Missed Payment", width: 220 },
  { field: "collection_notes", headerName: "Notes", width: 400 },
];

type CollectionsHistoryProps = {
    collection_activity: CollectionEvent[];
    onClick: (collection: CollectionEvent) => void;
};

export default function CollectionsHistory({collection_activity, onClick}:CollectionsHistoryProps) {
  const [selectedEvent, setSelectedEvent] = useState<CollectionEvent | null>(null);
  console.log("collections_history", collection_activity);

  const onRowClick = (params: any, event: any) => {
    // console.log(params.row.invoice_number);
    // const collection = collections_history.find((val: any) => val.id === params.row.id);
    // //@ts-ignore
    // onClick(collection as CollectionEvent);
    setSelectedEvent(params.row as CollectionEvent);
  };

  if(collection_activity?.length === 0) return <NoteText>No collections history found</NoteText>;

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Title>Collections History</Title>
      <DataGrid
        rows={collection_activity}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection={false}
        onRowClick={onRowClick}
        getRowId={(row) => row.id}
        sx={{ '& .MuiDataGrid-row': { cursor: 'pointer' } }}
      />
      <CollectionsEventModal collection_event={selectedEvent} open={!!selectedEvent} setOpen={() => setSelectedEvent(null)}/>
    </div>
  );
}

const NoteText = styled.div`
  font-size: 1rem;
  color: grey;
  font-style: italic;
`;