import axios from 'axios';

const TEST_TAGS = [
  
];

const getOutcomeOptions = async (): Promise<OutcomeData> => {
  const endpoint = "/collections/options";

  // const additionalOptions = [
  //   {
  //     id: 40,
  //     txt: "Payment",
  //   },
  //   {
  //     id: 41,
  //     txt: "Promise to Pay"
  //   }
  // ];
    
    try {
      const res = await axios.get(endpoint);
      console.log("outcome option res", res.data);
      // res.data.collection_status = additionalOptions.concat(res.data.collection_status);
      return res.data as OutcomeData;
    } catch(error) {
      console.error("Error fetching outcome options:", error);
      throw new Error("Failed to fetch outcome options. Please try again later.");
    }
};


const getLead = async (agent_email: string): Promise<Lead> => {
  const endpoint = `/collections/lead/${agent_email}`;
  let errorString = ""

  try {
    const response = await axios.get(endpoint);

    // Check for 204 status or missing data
    if (response.status === 204 || !response?.data) {
      errorString = "Lead not found"
      throw new Error("204 Lead not found");
    }

    const mappedActivity = response.data?.collection_activity?.map((a: CollectionEvent, idx: number) => {
      return { ...a, id: idx.toString(), date: "7/11/2024"}
    })

    return {...response.data, collection_activity: mappedActivity} as Lead;
  } catch (error: any) {
    // Handle the error gracefully
    console.error("Error fetching lead:", error);
    throw new Error(error.response?.data?.message ?? errorString ?? "An unspecified error occurred. Please try again later.");
  }
};

const unlockLead = async (agent_email: string, customerID: string): Promise<Lead> => {
  const endpoint = `/collections/lead/unlock`;
  console.log("unlocking lead", endpoint);

  try {
    const response = await axios.post(endpoint, {
      email: agent_email,
      internal_id: customerID
    });
    return response.data as Lead;
  } catch (error: any) {
    // Handle the error gracefully
    console.error("Error fetching lead:", error);
    throw new Error(error.response?.data?.message ?? "An unspecified error occurred. Please try again later.");
  }
};

const submitOutcome = async (body: OutcomeBody) => {
    const endpoint = "/collections/outcome"
    console.log("submitting outcome...", body);
  
    try {
      const res = await axios.post(endpoint, body);
      console.log("outcome submission res", res)
      return res.data;  
    } catch (error) {
      throw error;
    }
};

// const getCollectionsHistory = async (agent_email: string, customerID: string): Promise<CollectionsHistory> => {};

export {
    getLead,
    unlockLead,
    submitOutcome,
    getOutcomeOptions,
}