import React from "react";
import { PageView } from "../../GlobalComponents";
import styled from "@emotion/styled";
import { formatCurrency } from "../../util";

export default function LeadHistory() {
  return (
    <PageView>
      <Container>
        <div>Overview</div>
        <Seperator />
        <Body>
          <MetricContainer>
            <MetricHeader>
              <MetricHeaderText>Leads</MetricHeaderText>
            </MetricHeader>
            <MetricSeperator />
            {/* <MetricsBody>
              <MetricsTooltip>Total Resolved Leads:</MetricsTooltip>
              <MetricValue>{fakeMetrics.resolved_leads}</MetricValue>
            </MetricsBody> */}
          </MetricContainer>
          <MetricContainer>
            <MetricHeader>
              <MetricHeaderText>Collections</MetricHeaderText>
            </MetricHeader>
            <MetricSeperator />
            {/* <MetricsBody>
              <MetricsTooltip>Total Collected Revenue:</MetricsTooltip>
              <MetricValue>{formatCurrency(fakeMetrics.total_collected_revenue)}</MetricValue>
            </MetricsBody> */}
          </MetricContainer>
        </Body>
      </Container>
    </PageView>
  );
}


const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
`;

const MetricContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 250px;
  height: 125px;
  border: 2px solid #EDEDED;
  margin: 5px;
  border-radius: 5px;
  background-color: white;
`;

const MetricHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const MetricHeaderText = styled.p`
  font-size: 0.9em;
  color: grey;
  margin: 0;
  padding-left: 40px;
  margin-bottom: 10px;
  padding-top: 5px;
`;

const MetricSeperator = styled.div`
  width: 100%;
  height: 2px;
  background-color: #EDEDED;
`;

const MetricsBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 40px;
  padding-top: 20px;
  width: 100%;
`;

const MetricsTooltip = styled.p`
  font-size: 0.9em;
  color: lightgrey;
  margin: 0;
  margin-right: 5px;
  text-align: left;
`;

const MetricValue = styled.p`
  font-size: 1.3em;
  color: charcoal;
  margin: 0;
  text-align: left;
`;

const Seperator = styled.div`
  width: 100%;
  height: 2px;
  background-color: #EDEDED;
  margin: 3em 0;
`;