import { useMsal } from "@azure/msal-react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import LoginPage from "./LoginPage";
// import MSLogo from "../../assets/ms-logo-and-text.svg";

type LoginProps = {
  inProgress: string;
};

export default function Login({ inProgress }: LoginProps) {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect();
  };

  return (
    <Wrapper>
      {/* <MSLogo /> */}
      {inProgress === "login" ? (
        <Typography>Logging in...</Typography>
      ) : (
        <LoginPage onLogin={handleLogin} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.main`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 2em;
`;


