import React, { useState, useEffect } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { BounceLoader } from "react-spinners";

type OutcomeModalProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
};

export default function GetLeadModal({open, setOpen}: OutcomeModalProps) {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  const loadProgress = () => {
    setProgress(0);
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 200);
    return () => {
      clearInterval(timer);
    };
  };

  useEffect(() => {
    if(open) {
      loadProgress();
    }
  },[open]);

  useEffect(() => {
    if (progress === 100) {
      onProgressFilled();
    }
  }, [progress]);

  const onProgressFilled = () => {
    navigate(`/lead/213968`,);
    setOpen(false);
  };

  return (
    <Dialog onClose={() => setOpen(false)} open={open}>
        <ModalContent>
            <DialogTitle>Getting New Lead</DialogTitle>
            <p>Please wait while we get you a new lead...</p>
            <ProgressText>{progress}%</ProgressText>
            <BounceLoader color="#33CCFF" />
        </ModalContent>
    </Dialog>
  );
}

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 500px;
    height: 300px;
    padding: 5rem 3rem;
    align-items: center;
`;

const ProgressText = styled.p`
    color: #33CCFF;
    font-size: 1.2rem;
    font-weight: bold;
`;