import React from "react";
import styled from "@emotion/styled";
import { COLORS } from "../Theme";
import Tooltip from '@mui/material/Tooltip';

function toKebabCase(str: string) {
  return str.replace(/([a-z])([A-Z])/g, "$1-$2")
  .replace(/[\s_]+/g, '-')
  .toLowerCase();
}

type StatusProps = {
    status: InvoiceStatus | PaymentStatus;
    textStyle?: React.CSSProperties;
};

const getStatusTooltip = (status: InvoiceStatus | PaymentStatus | Outcome | "No Status") => {
    switch (status) {
      case "overdue":
        return "This invoice is overdue";
      case "paid":
        return "This invoice has been paid";
      case "pending":
        return "This invoice is pending";
      case "success":
        return "This payment was successful";
      case "failed":
        return "This payment failed";
      case "Paid In Full":
        return "This invoice has been paid";
      case "Collections":
        return "This invoice is in collections";
      case "No Status":
        return "No collections status available";
      case "Open":
        return "This invoice is open";
      case "payment":
        return "A Payment was collected";
      case "promise to pay":
        return "A promise to pay was made";
      case "no contact":
        return "No contact was made";
      default:
        return "";
    }
};

export default function Status({ status, textStyle }:StatusProps) {
  return (
    <Tooltip title={getStatusTooltip(status)} arrow>
      <StatusContainer className={toKebabCase(status.toLowerCase())}>
        <StatusDot className={toKebabCase(status.toLowerCase())} />
        <StatusText style={{...textStyle}} className={toKebabCase(status.toLowerCase())}>{status}</StatusText>
      </StatusContainer>
    </Tooltip>
  );
}

const StatusContainer = styled.p`
  font-size: 1em;
  color: #354669;
  background-color: #f0f4f5;
  border-radius: 50px;
  padding: 0.5em;
  text-transform: capitalize;
  cursor: pointer;
  &.overdue {
    background-color: ${COLORS.RED_LIGHT};
    color: ${COLORS.RED};
  }
  &.failed {
    background-color: ${COLORS.RED_LIGHT};
    color: ${COLORS.RED};
  }
  &.paid {
    background-color: ${COLORS.GREEN_LIGHT};
    color: ${COLORS.GREEN};
  }
  &.payment {
    background-color: ${COLORS.GREEN_LIGHT};
    color: ${COLORS.GREEN};
  }
  &.success {
    background-color: ${COLORS.GREEN_LIGHT};
    color: ${COLORS.GREEN};
  }
  &.pending {
    background-color: ${COLORS.ORANGE_LIGHT};
    color: ${COLORS.ORANGE};
  }
  &.open {
    background-color: ${COLORS.ORANGE_LIGHT};
    color: ${COLORS.ORANGE};
  }
  &.open {
    background-color: ${COLORS.ORANGE_LIGHT};
    color: ${COLORS.ORANGE};
  }
  &.deposited {
    background-color: ${COLORS.GREEN_LIGHT};
    color: ${COLORS.GREEN};
  }
  &.promise-to-pay {
    background-color: ${COLORS.ORANGE_LIGHT};
    color: ${COLORS.ORANGE};
  }
  &.no-contact {
    background-color: ${COLORS.ORANGE_LIGHT};
    color: ${COLORS.ORANGE};
  }
  &.collections {
    background-color: ${COLORS.ORANGE_LIGHT};
    color: ${COLORS.ORANGE};
  }
`;

const StatusText = styled.span`
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
    border-radius: 0.3rem;
    &.overdue {
        background-color: ${COLORS.RED_LIGHT};
        color: ${COLORS.RED};
    }
    &.failed {
        background-color: ${COLORS.RED_LIGHT};
        color: ${COLORS.RED};
    }
    &.paid {
        background-color: ${COLORS.GREEN_LIGHT};
        color: ${COLORS.GREEN};
    }
    &.payment {
        background-color: ${COLORS.GREEN_LIGHT};
        color: ${COLORS.GREEN};
    }
    &.success {
        background-color: ${COLORS.GREEN_LIGHT};
        color: ${COLORS.GREEN};
    }
    &.pending {
        background-color: ${COLORS.ORANGE_LIGHT};
        color: ${COLORS.ORANGE};
    }
    &.open {
        background-color: ${COLORS.ORANGE_LIGHT};
        color: ${COLORS.ORANGE};
    }
    &.deposited {
        background-color: ${COLORS.GREEN_LIGHT};
        color: ${COLORS.GREEN};
    }
    &.collections {
        background-color: ${COLORS.ORANGE_LIGHT};
        color: ${COLORS.ORANGE};
    }
    &.promise-to-pay {
        background-color: ${COLORS.ORANGE_LIGHT};
        color: ${COLORS.ORANGE};
    }
    &.no-contact {
        background-color: ${COLORS.ORANGE_LIGHT};
        color: ${COLORS.ORANGE};
    }
`;

const StatusDot = styled.span`
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    display: inline-block;
    // margin-right: 0.5rem;
    margin-bottom: 0.1rem;
    margin-left: 0.5rem;
    background-color: #354669;
    &.overdue {
        background-color: ${COLORS.RED};
    }
    &.failed {
        background-color: ${COLORS.RED};
    }
    &.paid {
        background-color: ${COLORS.GREEN};
    }
    &.payment {
        background-color: ${COLORS.GREEN};
    }
    &.success {
        background-color: ${COLORS.GREEN};
    }
    &.pending {
        background-color: ${COLORS.ORANGE};
    }
    &.open {
        background-color: ${COLORS.ORANGE};
    }
    &.deposited {
        background-color: ${COLORS.GREEN};
    }
    &.collections {
        background-color: ${COLORS.ORANGE};
    }
    &.promise-to-pay {
        background-color: ${COLORS.ORANGE};
    }
    &.no-contact {
        background-color: ${COLORS.ORANGE};
    }
`;