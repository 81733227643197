import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getInvoice, getInvoices } from "./api";
import { PageView } from "../../GlobalComponents";
import styled from "@emotion/styled";
import InvoicePage from "./InvoicePage";
import InvoicesScrollList from "./InvoicesScrollList";

export default function Invoice() {
  const params = useParams();
  const { customer_id, invoice_id } = params;
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState<string>(invoice_id as string);

  const { data: invoice, isLoading: invoiceLoading } = useQuery({
    queryKey: ["invoice", invoice_id, selectedInvoiceNumber],
    queryFn: () => getInvoice(customer_id as string, selectedInvoiceNumber || invoice_id as string),
    enabled: !!invoice_id,
  });

  const { data: invoices, isLoading: invoicesLoading } = useQuery({
    queryKey: ["invoices", customer_id],
    queryFn: () => getInvoices(customer_id as string),
    enabled: !!customer_id,
  });

  useEffect(() => {
    console.log("INVOICES", invoices);
  }, [invoices]);

  // @ts-ignore
  // const invoice_number = invoice.invoice_number;

  return (
    <PageView>
      <div style={{display: "flex", width: "100vw"}}>
        <LeftContainer>
          <InvoicesScrollList invoices={invoices as Invoice[]} onClick={setSelectedInvoiceNumber} isLoading={invoicesLoading}/>
        </LeftContainer>
        <RightContainer>
          <InvoicePage invoice={invoice as Invoice} isLoading={invoiceLoading}/>
        </RightContainer>
      </div>
    </PageView>
  );
}

const LeftContainer = styled.div`
  margin-right: 10px;
`;

const RightContainer = styled.div`
  margin-left: 10px;
  padding: 10px;
  border: 1px solid lightgrey;
  border-radius: 0.5rem;
  width: 75vw;
  display: flex;
  justify-content: center;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 15vw;
  height: 100vh;
`;