import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {},
  palette: {
    mode: "light",
    primary: {
      main: "#336699",
    },
    secondary: {
      main: "#00ccff",
    },
  },
});

const COLORS = {
  PRIMARY: "#336699",
  SECONDARY: "#00ccff",
  RED: "red",
  RED_LIGHT: "#ffd2cc",
  RED_DARK: "#ff0000",
  GREEN: "green",
  GREEN_LIGHT: "#e6ffe6",
  GREEN_DARK: "#00ff00",
  YELLOW: "#c7c11a",
  YELLOW_LIGHT: "#ffffe6",
  YELLOW_DARK: "#ffff00",
  ORANGE: "orange",
  ORANGE_LIGHT: "#F2EFEF",
  ORANGE_DARK: "#c49302",
  GREY: "#354669",
  GREY_LIGHT: "#F0F4F5",
  GREY_MEDIUM: "grey",
  GREY_MEDIUM2: "#cccccc",
  GREY_MEDIUM3: "#f0f0f0",
  GOLD: "#856404",
  GOLD_LIGHT: "#fff3cd",
};

export default theme;

export {
  COLORS,
}
