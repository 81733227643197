const formatDateString = (dateString: string): string => {
  if (!dateString) return "";

  const date = new Date(dateString);

  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 since getMonth() returns 0-indexed month
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear());

  const newDateString = `${month}/${day}/${year}`;

  return newDateString;
};

export default formatDateString;
