import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./assets/fonts/Gotham-Bold.ttf";
import "./assets/fonts/Gotham-Medium.ttf";
import "./assets/fonts/Gotham-Book.otf";
import "./assets/fonts/Lato-Bold.ttf";
import "./assets/fonts/Lato-Regular.ttf";

import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { initAxiosDefaultValues } from './util/initAxiosDefaultValues';
import { ENV } from "./config";
// import { GlobalStyles } from "./GlobalComponents/GlobalStyles";

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));

const pca = new PublicClientApplication({
  auth: {
    clientId: "abd8e51f-a2b9-4475-aae8-c31c6cd5ce93",
    authority:
      "https://login.microsoftonline.com/e485c427-0599-4dbd-86d9-add2e5942a49",
    redirectUri: "/",
  },
});

pca.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    // @ts-ignore
    pca.setActiveAccount(event.payload.account);
  }
});

initAxiosDefaultValues(ENV);

root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
