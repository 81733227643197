import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Login from "../Login";
import axios from "axios";
import NotAuthorized from "./NotAuthorized";
import { adminEmails } from "../../config";
import { useAuthSession } from "../auth-context";

function PrivateRoute() {
  const { instance, accounts, inProgress } = useMsal();
  const activeAccout = instance.getActiveAccount();
  const { pathname } = useLocation();
  const { email } = useAuthSession();

  const getIdToken = async () => {
    if (inProgress === "none") {
      const request = {
        scopes: ["openid"],
        account: accounts[0],
      };
      const response = await instance.acquireTokenSilent(request);
      axios.defaults.headers.common["Authorization"] =
        `Basic ${response.accessToken}`;
      // console.log("token", response.accessToken);
      return response.accessToken;
    }
  };

  useEffect(() => {
    if (accounts.length > 0) {
      getIdToken();
    }
  }, []);

  if (accounts.length > 0) {
    if (adminEmails.includes(email)) {
      return <Outlet />;
    } else {
      return <NotAuthorized />;
    }
  }

  return <Login inProgress={inProgress} />;
}

export default PrivateRoute;
