import parseDate from "./parseDate";

const parseNotes = (notesString: string | undefined) => {
  if (!notesString) return [];

  const notesArray = notesString.split(/\r?\n/);

  // Function to parse the date from the note
  const parseDate = (dateString: string) => {
    const dateFormats = [
      "M.D.YY",
      "MM.DD.YY",
      "M/D/YY",
      "MM/DD/YY",
      "M.D.YYYY",
      "MM.DD.YYYY",
      "M/D/YYYY",
      "MM/DD/YYYY",
    ];
    const parsedDate = dateFormats
      .map((format) => {
        return Date.parse(
          dateString
            .replace(/\b(\d{1})\b/g, "0$1")
            .replace(/\./g, "/")
            .replace(/-/g, "/")
            .replace(/ /g, "/")
            .replace(/(\d{4})[/.](\d{1,2})[/.](\d{1,2})/g, "$2/$3/$1"),
        );
      })
      .find((date) => !isNaN(date));
    if(!parsedDate) return null;
    return isNaN(parsedDate) ? null : new Date(parsedDate);
  };

  // Create array of objects with date and description
  const notesObjects = notesArray.map((note) => {
    const dateRegex = /^(\d{1,2}[./]\d{1,2}[./]\d{2,4})/; // Regex to match date at the beginning
    const dateMatch = note.match(dateRegex);
    let date = null;
    if (dateMatch) {
      date = parseDate(dateMatch[1]);
    }
    const description = note.replace(dateRegex, "").trim(); // Remove date from description
    return { date, description };
  });

  //remote items in the array with no date or description
  for (let i = notesObjects.length - 1; i >= 0; i--) {
    if (!notesObjects[i].date || !notesObjects[i].description) {
      notesObjects.splice(i, 1);
    }
  }

  console.log("notes objects", notesObjects);

  return notesObjects;
};

export default parseNotes;