import React, { useEffect, useState } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styled from "@emotion/styled";
import Modal from "../../../GlobalComponents/Modal";
import { MoonLoader } from "react-spinners";
import { useMutation } from "@tanstack/react-query";
import { submitOutcome } from "../api";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../../Theme";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { buildSuboutcomes } from "./config";
import { useMsal } from "@azure/msal-react";
import { useAuthSession } from "../../../Auth";

const customerWasContactedEnums = [
  "Payment",
  "Promise to Pay",
  "Spoke with Customer",
];

const paymentSubOptions = [
  "collected full balance",
  "collected partial balance",
];

const contactMethods = [
    "phone",
    "email",
    "text",
];

const capitalizeFirstCharOfEachWord = (str: string) => {
    return str.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
};

type OutcomeModalProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    outcomeData?: OutcomeData;
    internalId: string;
};

export default function OutcomeModal({open, setOpen, outcomeData, internalId }: OutcomeModalProps) {
  
  const { email } = useAuthSession();
  const navigate = useNavigate();
  const [outcome, setOutcome] = useState<any>("");
  const [outcomeError, setOutcomeError] = useState<string>("");
  const [subOutcome, setSubOutcome] = useState<string>("")
  const [subOutcomeError, setSubOutcomeError] = useState<string>("");
  const [contactMethod, setContactMethod] = useState<string>("");
  const [contactMethodError, setContactMethodError] = useState<string>("");
  const [notes, setNotes] = useState("");
  const [notesError, setNotesError] = useState<string>("");
  const [promiseDate, setPromiseDate] = useState<Date | null>(null);
  const [promiseDateError, setPromiseDateError] = useState<string>("");
  const [paymentSubOutcome, setPaymentSubOutcome] = useState<string>("");
  const [paymentSubOutcomeError, setPaymentSubOutcomeError] = useState<string>("");
  const [paymentPlan, setPaymentPlan] = useState<string>("");
  const [paymentPlanError, setPaymentPlanError] = useState<string>("");
  const [paymentFrequency, setPaymentFrequency] = useState<string>("");
  const [paymentFrequencyError, setPaymentFrequencyError] = useState<string>("");
  const [reasonForMissedPayment, setReasonForMissedPayment] = useState<string>("");
  const [reasonForMissedPaymentError, setReasonForMissedPaymentError] = useState<string>("");
  const [timeToCall, setTimeToCall] = useState<string>("");
  const [timeToCallError, setTimeToCallError] = useState<string>("");

  const formErrors = {
    outcome: outcomeError,
    contactMethod: contactMethodError,
    notes: notesError,
    paymentSubOutcome: paymentSubOutcomeError,
    reasonForMissedPayment: reasonForMissedPaymentError,
    timeToCall: timeToCallError,
  };

  useEffect(() => {
    console.log("formErrors", formErrors);
  }, [formErrors]);

  const customerWasContacted = outcome === "Contacted Customer";

  const {
    collection_outcomes: outcomeOptions,
    collection_suboutcomes,
    reason_for_missed_payment: missedPaymentReasons,
    contact_method: contactMethods,
    time_to_call,
    payment_plan_frequency,
    payment_amount,
    payment_plan_setup,
  } = outcomeData ?? {};

  const sub_outcomes = buildSuboutcomes(collection_suboutcomes);
  console.log("sub outcomes", sub_outcomes)

  const {
    mutate: outcomeMutate,
    status: outcomeStatus,
    isSuccess: outcomeSuccess,
  } = useMutation({
    mutationFn: submitOutcome,
    onSuccess: (data, variables, context) => {},
    onError: (data) => {},
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Outcome", outcome);
    let formError = false;
    if(!outcome) {
        setOutcomeError("Outcome is required *");
        formError = true;
    } 
    if(!subOutcome) {
      setSubOutcomeError("Sub Outcome is required")
      formError = true;
    }
    if(customerWasContacted) {
      if(!reasonForMissedPayment) {
        setReasonForMissedPaymentError("Reason for Missed Payment is required *");
        formError = true;
      }
      if(!timeToCall) {
          setTimeToCallError("Time to Call is required *");
          formError = true;
      }
      if(subOutcome === "Payment") {
        if(!paymentSubOutcome) {
          setPaymentSubOutcomeError("Payment Amount is required *");
          formError = true;
        }
        if(paymentPlan === "Yes" && !paymentFrequency) {
          setPaymentFrequencyError("Payment Plan Frequency is required")
          formError = true;
        }
      }
    }
    if(!contactMethod) {
        setContactMethodError("Contact Method is required *");
        formError = true;
    }
    if(!notes) {
        setNotesError("Notes is required *");
        formError = true;
    }

    
    if(formError) return;
    const formattedDate = promiseDate ? new Date(promiseDate?.toISOString()).toLocaleDateString() : ""

    const body: OutcomeBody = {
      internalid: internalId,
      collection_outcomes: getNetsuiteId(outcome, "collection_outcomes"),
      collection_suboutcomes: getNetsuiteId(subOutcome, "collection_suboutcomes"),
      ms_collections_reas_miss_pay: getNetsuiteId(reasonForMissedPayment, "reason_for_missed_payment"),
      promise_to_pay_date: formattedDate,
      ms_time_to_call: getNetsuiteId(timeToCall, "time_to_call"),
      contact_method: getNetsuiteId(contactMethod, "contact_method"),
      collection_notes: notes,
      ms_collections_amt_add_pay: getNetsuiteId(paymentSubOutcome, "payment_amount"),
      ms_collections_pay_plan_setup: getNetsuiteId(paymentPlan, "payment_plan_setup"),
      ms_collections_freq_add_pay: getNetsuiteId(paymentFrequency, "payment_plan_frequency"),
      email: email,
    }

    console.log("body", body)

    outcomeMutate(body);
  };

  const onChangeOutcome = (e: SelectChangeEvent) => {
    setOutcome(e.target.value as Outcomes);
    setSubOutcome("");
    setSubOutcomeError("");
    clearAllPaymentOptions();
    if (e.target.value === "") {
      setOutcomeError("Outcome is required");
    } else {
      setOutcomeError("");
    }
  };

  const onChangeSubOutcome = (e: SelectChangeEvent) => {
    setSubOutcome(e.target.value as string);
    clearAllPaymentOptions();
    if (e.target.value === "") {
      setSubOutcomeError("Sub Outcome is required");
    } else {
      setSubOutcomeError("");
    }
  };

  const clearAllPaymentOptions = () => {
    setPaymentSubOutcome("");
    setPaymentSubOutcomeError("");
    setPaymentPlan("");
    setPaymentPlanError("");
    setPaymentFrequency("");
    setPaymentFrequencyError("");
  }

  const onChangeContactMethod = (e: SelectChangeEvent) => {
    setContactMethod(e.target.value as string);
    if (e.target.value === "") {
      setContactMethodError("Contact Method is required");
    } else {
      setContactMethodError("");
    }
  };

  const onChangeNotes = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
    if (e.target.value === "") {
      setNotesError("Notes is required");
    } else {
      setNotesError("");
    }
  };

  const onSelectDate = (date: Date | null) => {
    setPromiseDate(date);
  };

  const onClose = () => {
    if(outcomeSuccess) {
        navigate("/");
    } else {
        setOpen(false);
    }
  };

  const getNetsuiteId = (txt: string, optionSet: string) => {
    if(!outcomeData) return "";
    console.log("txt", txt, "optionSet", optionSet)
    //@ts-ignore
    const option = outcomeData[optionSet].find((opt) => opt.txt === txt);
    console.log("option", option)
    return option?.id || "";
  }

  React.useEffect(() => {
    console.log("outcome loading changed", outcomeStatus)
  }, [outcomeStatus]);

  const renderDatePicker = () => {
    if(subOutcome === "Promise To Pay") {
      return (
        <SelectContainer>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SelectLabel>Promise to Pay Date</SelectLabel>
            <DatePicker
              label={promiseDateError ?? "Promise to Pay Date"}
              onChange={onSelectDate}
              sx={{ width: "100%" }}
              disablePast
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: "outlined",
                  error: !!promiseDateError, // Bolean
                },
              }}
            />
          </LocalizationProvider>
        </SelectContainer>
      );
    }
  };

  const renderPaymentOptions = (outcome: string) => {
    if(subOutcome === "Payment") {
      return (
        <SelectContainer>
          {paymentSubOutcomeError ? (
            <ErrorText>{paymentSubOutcomeError}</ErrorText>
          ) : (
            <SelectLabel>
              Payment Amount *
            </SelectLabel>
          )}
          <Select
            native
            value={paymentSubOutcome}
            onChange={(e) => {
              setPaymentSubOutcomeError("");
              setPaymentPlan("");
              setPaymentPlanError("");
              setPaymentFrequency("");
              setPaymentFrequencyError("");
              setPaymentSubOutcome(e.target.value as string)}}
            inputProps={{
              name: "payment amount",
              id: "payment-amount-native-simple",
            }}
            sx={{ width: "100%" }}
          >
            <option aria-label="None" value="" />
            {payment_amount?.map((option) => (
              <option value={option.txt}>
                {capitalizeFirstCharOfEachWord(option.txt)}
              </option>
            ))}
          </Select>
        </SelectContainer>
      );
    }
  };

  const renderPaymentFrequencyOptions = () => {
    if(paymentPlan === "Yes") {
      return (
        <SelectContainer>
          {paymentFrequencyError ? (
            <ErrorText>{paymentFrequencyError}</ErrorText>
          ) : (
            <SelectLabel>
              Payment Plan Frequency*
            </SelectLabel>
          )}
          <Select
            native
            value={paymentFrequency}
            onChange={(e) => {
              setPaymentFrequencyError("");
              setPaymentFrequency(e.target.value as string)}}
            inputProps={{
              name: "payment frequency",
              id: "payment-frequency-native-simple",
            }}
            sx={{ width: "100%" }}
          >
            <option aria-label="None" value="" />
            {payment_plan_frequency?.map((option) => (
              <option value={option.txt}>
                {capitalizeFirstCharOfEachWord(option.txt)}
              </option>
            ))}
          </Select>
        </SelectContainer>
      )
    }
  };

  const renderPaymentPlanOptions = () => {
    if(paymentSubOutcome === "Collected Partial Balance") {
      return (
        <SelectContainer>
          {paymentPlanError ? (
            <ErrorText>{paymentPlanError}</ErrorText>
          ) : (
            <SelectLabel>
              Payment Plan
            </SelectLabel>
          )}
          <Select
            native
            value={paymentPlan}
            onChange={(e) => {
              setPaymentPlanError("");
              setPaymentFrequency("");
              setPaymentFrequencyError("");
              setPaymentPlan(e.target.value as string)}}
            inputProps={{
              name: "payment plan",
              id: "payment-native-simple",
            }}
            sx={{ width: "100%" }}
          >
            <option aria-label="None" value="" />
            {payment_plan_setup?.map((option) => (
              <option value={option.txt}>
                {capitalizeFirstCharOfEachWord(option.txt)}
              </option>
            ))}
          </Select>
        </SelectContainer>
      )
    }
  };

  const renderReasonForMissedPayment = () => {
    if (customerWasContacted) {
      return (
        <SelectContainer>
          {reasonForMissedPaymentError ? (
            <ErrorText>{reasonForMissedPaymentError}</ErrorText>
          ) : (
            <SelectLabel>
              Reason for Missed Payment *
            </SelectLabel>
          )}
          <Select
            native
            value={reasonForMissedPayment}
            onChange={(e) => {
              setReasonForMissedPaymentError("");
              setReasonForMissedPayment(e.target.value as string)
            }}
            inputProps={{
              name: "outcome",
              id: "outcome-native-simple",
            }}
            sx={{ width: "100%" }}
          >
            <option aria-label="None" value="" />
            {outcomeData?.reason_for_missed_payment.map((option) => (
              <option value={option.txt}>
                {capitalizeFirstCharOfEachWord(option.txt)}
              </option>
            ))}
          </Select>
        </SelectContainer>
      );
    }
  };

  const renderTimeToCall = () => {
    if (customerWasContacted) {
      return (
        <SelectContainer>
          {timeToCallError ? (
            <ErrorText>{timeToCallError}</ErrorText>
          ) : (
            <SelectLabel>Time to Call *</SelectLabel>
          )}
          <Select
            native
            value={timeToCall}
            onChange={(e) => {
              setTimeToCallError("");
              setTimeToCall(e.target.value as string);
            }}
            inputProps={{
              name: "outcome",
              id: "outcome-native-simple",
            }}
            sx={{ width: "100%" }}
          >
            <option aria-label="None" value="" />
            {time_to_call?.map((option) => (
              <option value={option.txt}>
                {capitalizeFirstCharOfEachWord(option.txt)}
              </option>
            ))}
          </Select>
        </SelectContainer>
      );
    }
  };

  const renderSubOutcome = (outcome: string) => {
    console.log("outcome", outcome)
    if(!outcome) return null;

    return (<SelectContainer>
      {subOutcomeError ? (
        <ErrorText>{subOutcomeError}</ErrorText>
      ) : (
        <SelectLabel>Sub Outcome *</SelectLabel>
      )}
      {sub_outcomes ? (
        <Select
          native
          value={subOutcome}
          onChange={onChangeSubOutcome}
          inputProps={{
            name: "sub_outcome",
            id: "outcome-native-simple",
          }}
          sx={{ width: "100%" }}
        >
          <option aria-label="None" value="" />
          {
          outcome === "Contacted Customer" ?
          sub_outcomes.contact.map((option: OutcomeOption) => (
            <option value={option.txt}>
              {capitalizeFirstCharOfEachWord(option.txt)}
            </option>
          ))
          :
          sub_outcomes.no_contact.map((option: OutcomeOption) => (
            <option value={option.txt}>
              {capitalizeFirstCharOfEachWord(option.txt)}
            </option>
          ))
        }
        </Select>
      ) : (
        <div>Failed to get Sub Outcome Options</div>
      )}
    </SelectContainer>)
  }
     
  return (
    <Modal
      setOpen={setOpen}
      open={open}
      width={customerWasContacted ? "800px" : "500px"}
      height={"650px"}
      onClose={onClose}
    >
      {outcomeSuccess ? (
        <ModalContent>
          <DialogTitle sx={{ margin: 0, padding: 0 }}>
            Outcome Submitted
          </DialogTitle>
          <ModalForm onSubmit={() => navigate("/")}>
            <Button variant="outlined" type="submit">
              Return to Dashboard
            </Button>
          </ModalForm>
        </ModalContent>
      ) : outcomeStatus === "pending" ? (
        <ModalContent>
          <DialogTitle sx={{ margin: 0, padding: 0 }}>
            Submitting Outcome
          </DialogTitle>
          <LoaderContainer>
            <MoonLoader color={COLORS.PRIMARY} />
          </LoaderContainer>
        </ModalContent>
      ) : (
        <ModalContent>
          <DialogTitle sx={{ margin: 0, padding: 0 }}>
            Select Outcome
          </DialogTitle>
          <ModalForm onSubmit={handleSubmit}>
            <Grid
              columns={
                subOutcome === "Payment" ? 3 : customerWasContacted ? 2 : 1
              }
            >
              <Column>
                <SelectContainer>
                  {outcomeError ? (
                    <ErrorText>{outcomeError}</ErrorText>
                  ) : (
                    <SelectLabel>Outcome *</SelectLabel>
                  )}
                  {outcomeOptions ? (
                    <Select
                      native
                      value={outcome}
                      onChange={onChangeOutcome}
                      inputProps={{
                        name: "outcome",
                        id: "outcome-native-simple",
                      }}
                      sx={{ width: "100%" }}
                    >
                      <option aria-label="None" value="" />
                      {outcomeOptions.map((option) => (
                        <option value={option.txt}>
                          {capitalizeFirstCharOfEachWord(option.txt)}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <div>Failed to get Outcome Options</div>
                  )}
                </SelectContainer>
                {renderSubOutcome(outcome)}
                {renderReasonForMissedPayment()}
              </Column>
              <Column>
                {renderDatePicker()}
                {renderTimeToCall()}
                <SelectContainer>
                  {contactMethodError ? (
                    <ErrorText>{contactMethodError}</ErrorText>
                  ) : (
                    <SelectLabel>Contact Method *</SelectLabel>
                  )}
                  <Select
                    native
                    value={contactMethod}
                    onChange={onChangeContactMethod}
                    inputProps={{
                      name: "contactMethod",
                      id: "contactMethod-native-simple",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {contactMethods?.map((option) => (
                      <option value={option.txt}>
                        {capitalizeFirstCharOfEachWord(option.txt)}
                      </option>
                    ))}
                  </Select>
                </SelectContainer>
              </Column>
              <Column>
                {renderPaymentOptions(outcome)}
                {renderPaymentPlanOptions()}
                {renderPaymentFrequencyOptions()}
              </Column>
            </Grid>
            <SelectContainer>
              {notesError ? (
                <ErrorText>{notesError}</ErrorText>
              ) : (
                <SelectLabel>Notes *</SelectLabel>
              )}
              <TextField
                id="outlined-multiline-static"
                label="Notes"
                multiline
                rows={4}
                defaultValue=""
                value={notes}
                onChange={onChangeNotes}
              />
            </SelectContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: 75,
              }}
            >
              <Button variant="outlined" type="submit" sx={{ width: 410 }}>
                Submit
              </Button>
            </div>
          </ModalForm>
        </ModalContent>
      )}
    </Modal>
  );
}

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    gap: 1rem;
    width: 100%;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0rem 3rem;
    background-color: white;
    border-radius: 0.3em;
`;

const ModalForm = styled.form<{ columns?: number }>`
`;

const Grid = styled.div<{ columns?: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: 1rem;
`;

const SelectLabel = styled.p`
    font-size: 0.8em;
    color: #354669;
    margin: 0;
    padding: 0;
    text-align: left;
`;

const ErrorText = styled.p`
    font-size: 0.8em;
    color: red;
    margin: 0;
    padding: 0;
    text-align: left;
`;

const ModalHeader = styled.div``;

const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
`;

const SelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;
    width: 100%;
    height: 100px;
`;

{/* <ModalContent>
  <DialogTitle sx={{ margin: 0, padding: 0 }}>Outcome Submitted</DialogTitle>
  <ModalForm onSubmit={() => navigate("/")}>
    <Button variant="outlined" type="submit">
      Close
    </Button>
  </ModalForm>
</ModalContent>;  */}