import React from "react";
import { PageView, GetLeadModal } from "../../GlobalComponents";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import styled from "@emotion/styled"; 
import LeadHistory from "./LeadHistory";
import { useNavigate } from "react-router-dom";
import DevToolsModal from "../Lead/DevToolsModal";
import { devEmails } from "../../config";
import { useAuthSession } from "../../Auth";

export default function Dashboard() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [devToolsOpen, setDevToolsOpen] = React.useState(false);
  const { email } = useAuthSession();

  return (
    <PageView
      headerProps={{
        rightContent: devEmails.includes(email) && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => setDevToolsOpen(true)}
          >
            Dev Tools
          </Button>
        ),
      }}
    >
      <Container>
        <div style={{ marginBottom: 15 }}>Dashboard</div>
        <Button variant="outlined" onClick={() => navigate("/lead")}>
          Get New Lead
        </Button>
        <Seperator />
        {/* <LeadHistory /> */}
      </Container>
      <GetLeadModal open={open} setOpen={setOpen} />
      <DevToolsModal
        open={devToolsOpen}
        setOpen={setDevToolsOpen}
        methods={{}}
      />
    </PageView>
  );
}

const Container = styled.div`
  max-width: 90%;
  padding: 2em;
  background-color: white;
  border-radius: 0.3em;
  box-shadow: 0px 0px 10px #35466933;
`;

const Seperator = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ededed;
  margin: 3em 0;
`;
