import styled from "@emotion/styled";
import React from "react";
import { Header } from "../index";
import SideBar from "../sidebar";
// import CollapsingLogo from "../Navbar/CollapsingLogo";
import { useLocation } from "react-router-dom";

// This component handles page layout and styling

type PageViewProps = {
  children?: React.ReactNode;
  headerHeight?: number;
  headerProps?: {
    rightContent?: React.ReactNode;
  };
};

function PageView({ children, ...props }: PageViewProps) {
  const { pathname } = useLocation();
  return (
    <FullPage key={"page"}>
      {/* <CollapsingLogo /> */}
      <SideBar {...props.headerProps} />
      {/* <Navbar /> */}
      <div style={{padding: "0.5em"}}>
        <Wrapper>
          <Header {...props.headerProps}/>
          <div style={{overflowY: "scroll", height: "90%"}}>
            <ContentWrapper {...props} key={pathname}>
              {children}
            </ContentWrapper>
          </div>
        </Wrapper>
      </div>
    </FullPage>
  );
}

export default PageView;

const FullPage = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #f0f4f5;
  display: flex;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(39,39,43,1) 35%);
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  background-color: #e3e3e3;
  border-radius: 0.3em;
  overflow: hidden;
  width: 80%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  padding-left: 1em;
  padding-top: 1em;
  border-radius: 0.3em;
  max-width: 95%;
  height: 95%;
  // overflow: auto;
  // height: 100vh;
  width: 100vw;
  // box-shadow: inset 0px 0px 10px #35466933;
  background-color: rgba(0,0,0,0);
  // background-color: #19191a;
  padding-bottom: 12em;
`;
