function parseToZero(s: string) {
  if (s.trim() === ".00" || s.trim() === "0.00") {
    return 0;
  } else {
    return parseFloat(s);
  }
}

function countMissedPayments(invoices: Invoice[]) {
  let missedPayments = 0;

  // Get today's date
  const today = new Date();

  // Loop through each invoice
  invoices.forEach((invoice) => {
    if (!invoice.due_date && invoice.payments.length === 0)
      return missedPayments++;

    // Convert due date string to Date object
    const dueDate = new Date(invoice.due_date);

    // console.log("due date", dueDate);

    // // Check if due date is after today and payments array is empty
    // if (dueDate < today && invoice.payments.length === 0) {
    //   missedPayments++;
    // }

    // Check if amountremaining is 0
    if (parseToZero(invoice.amountremaining) === 0) {
      return;
    } else {
      missedPayments++;
    }

  });

  // console.log("missed payments", missedPayments);

  return missedPayments;
}

export default countMissedPayments;
