import React from "react";
import { Checkbox } from "@mui/material";
import styled from "@emotion/styled";
import PersonIcon from "../PersonIcon";
import { COLORS } from "../../../../Theme";
import { Loader } from "../../../../GlobalComponents";

const limitString = (str: string, limit: number) => {
  return str.length > limit ? str.substring(0, limit) + "..." : str;
};

type TeamMembersListProps = {
  teamMembers: TeamMember[] | undefined;
  selectedTeamMembers: TeamMember[];
  isLoading: boolean;
  onTeamMemberSelect: (teamMember: any) => void;
  showCheckbox?: boolean;
};

export default function TeamMembersList({ teamMembers, isLoading, onTeamMemberSelect, selectedTeamMembers, showCheckbox=false }: TeamMembersListProps) {


  console.log("selected team members", selectedTeamMembers)
  console.log("team members", teamMembers)

  return (
    <Wrapper>
      {isLoading ? (
        <div style={{height: 600}}>
          <Loader text="Loading Team Members" />
        </div>
      ) : (
        teamMembers &&
        teamMembers.map((teamMember: TeamMember, index: number) => (
          <TeamMemberRow
            key={teamMember.id}
            style={{
              backgroundColor: index % 2 === 0 ? "#f7f7f7" : "#fff",
            }}
          >
            <PersonIcon
              person={teamMember}
              onClick={() => showCheckbox && onTeamMemberSelect(teamMember)}
            />
            <InfoWrapper
              onClick={() => showCheckbox && onTeamMemberSelect(teamMember)}
              style={{ cursor: showCheckbox ? "pointer" : "default" }}
            >
              {/* <Name>{teamMember.name}</Name> */}
              <Email>{limitString(teamMember.email, 30)}</Email>
            </InfoWrapper>
            {showCheckbox ? (
              <Checkbox
                checked={
                  selectedTeamMembers.find(
                    (selectedTeamMember) =>
                      selectedTeamMember.email === teamMember.email,
                  ) !== undefined
                }
                onChange={() => onTeamMemberSelect(teamMember)}
                color="primary"
              />
            ) : (
              <div style={{ width: 40, height: 40 }} />
            )}
          </TeamMemberRow>
        ))
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 100%;
  align-items: center;
  margin: 5px;
  background-color: #fff;
  border: 1px solid #fff;
  // cursor: grab;
  touch-action: none;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const TeamMemberRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dotted lightgrey;
  padding: .7rem;
  width: 90%;
  padding-left: 40px;
`;

const Name = styled.div`
  color: ${COLORS.PRIMARY};
  font-size: 14px;
  text-overflow: ellipsis;
`;

const Email = styled.div`
  color: #333;
  font-size: 12px;
  text-overflow: ellipsis;
`;


const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  // background-color: orange;
  width: 60%;
`;