import React from "react";
import { BeatLoader } from "react-spinners";
import { COLORS } from "../Theme";
import styled from "@emotion/styled";

type LoaderProps = {
  text?: string;
  loaderColor?: string;
  isLoading: boolean;
};

const LoaderOverlay: React.FC<LoaderProps> = ({
  text,
  isLoading,
  loaderColor = "#33CCFF",
}) => {
  return (
    <>
      {isLoading && (
        <Overlay>
          <LoaderContainer>
            <BeatLoader color={loaderColor} />
            {text && <LoaderText color={loaderColor}>{text}</LoaderText>}
          </LoaderContainer>
        </Overlay>
      )}
    </>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Gray background with 0.3 opacity */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const LoaderText = styled.div<{color: string}>`
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.color};
`;

export default LoaderOverlay;
