import React from "react";
import { BeatLoader } from "react-spinners";
import { COLORS } from "../Theme";
import styled from "@emotion/styled";

type LoaderProps = {
    text?: string;
    color?: string;
};

export default function Loader({ text, color=COLORS.PRIMARY }: LoaderProps) {
  return (
    <LoaderContainer>
      <BeatLoader color={color} />
      {text && <LoaderText>{text}</LoaderText>}
    </LoaderContainer>
  );
}

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px;
`;

const LoaderText = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: ${COLORS.PRIMARY};
`;