import team_members from "./team_members.json";
import { generateRandomColor } from "./functions";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const getTeamMembers = async (): Promise<TeamMember[]> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // endpoint goes here
      const teamMembers = team_members as string[];
      const mapped = teamMembers.map((member) => {
        return {
          email: member,
          id: uuidv4(),
          color: generateRandomColor(),
        };
      });
      resolve(mapped);
    }, 1000);
  });
};

const getSavedSearches = async (): Promise<SavedSearch[]> => {

  const endpoint = "/collections/safe-search";

  return new Promise((resolve, reject) => {
    const res = axios.get(endpoint).then((res) => res.data);
    console.log("saved searches res", res)
    
    resolve(res);
  });
};

const getData = async () => {
  const teamMembers = await getTeamMembers();
  console.log("get team members in data", teamMembers)
  const teamMemberColors: { [key: string]: string } = {};
  teamMembers.forEach((member: TeamMember) => {
    //@ts-ignore
    teamMemberColors[member.email] = member.color;
  });

  const savedSearches = await getSavedSearches();
  console.log("get saved searches in data", savedSearches)
  // if(savedSearches) {
  //   savedSearches.forEach((search) => {
  //     search.assigned.forEach((assignee) => {
  //       const color = teamMemberColors[assignee.email];
  //       if (color) {
  //         assignee.color = color;
  //       } else {
  //         // If the assignee's email is not found in team members,
  //         // generate a random color for the assignee
  //         assignee.color = generateRandomColor();
  //       }
  //     });
  //   });
  // }

  return {savedSearches, teamMembers};
};

const getSearchAssignedTeamMembers = async (
  searchId: string,
  allTeamMembers: TeamMember[],
): Promise<TeamMember[]> => {
  const endpoint = `/collections/safe-search-user/${searchId}`;

  return new Promise((resolve, reject) => {
    const res: Promise<void | TeamMember[]> = axios
      .get(endpoint)
      .then((res) => {
        const assignedUsers: TeamMemberRes = res.data;
        console.log("assignedUsers", assignedUsers)

        // check is assigned members are found within all team members
        const foundMembers = allTeamMembers.filter((member) => {

          const foundMember = assignedUsers.find(
            (assignedUser) => assignedUser.email === member.email,
          );

          if(foundMember) {
            return member;
          }
        }
        );

        console.log("found members", foundMembers)

        // return found members that already have id and color assigned
        // this will not solve for a team member that is assigned but not found in all team members
        resolve(foundMembers);
      });

    return res;
  });
};

const patchUpdateSavedSearch = async (searchId: string, assignees: TeamMember[]) => {
    const endpoint =
      "/collections/safe-search-user";

    const body: UpdateSafeSearchBody = {
      emails: assignees.map((assignee) => assignee.email),
      safe_search_id: parseInt(searchId),
    };

    return new Promise((resolve, reject) => {
      const res = axios.post(endpoint, body);
      resolve(res);    
    });
};

export {
    getTeamMembers,
    getSavedSearches,
    patchUpdateSavedSearch,
    getData,
    getSearchAssignedTeamMembers,
}