import React, { useState, useEffect } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Modal from '@mui/material/Modal';
import styled from "@emotion/styled";
import { BounceLoader } from "react-spinners";

type OutcomeModalProps = {
    open: boolean;
};

export default function LoadingModal({open}: OutcomeModalProps) {
  const [progress, setProgress] = useState(0);

  const loadProgress = () => {
    setProgress(0);
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 5));
    }, 500);
    return () => {
      clearInterval(timer);
    };
  };

  useEffect(() => {
    if(open) {
      loadProgress();
    }
  },[open]);

  return (
    <Modal open={open} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <ModalContent>
            <DialogTitle>Getting New Lead</DialogTitle>
            <p>Please wait while we get you a new lead...</p>
            <ProgressText>{progress}%</ProgressText>
            <BounceLoader color="#33CCFF" />
        </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 500px;
    height: 300px;
    padding: 5rem 3rem;
    align-items: center;
    background-color: white;
    border-radius: 0.3em;
`;

const ProgressText = styled.p`
    color: #33CCFF;
    font-size: 1.2rem;
    font-weight: bold;
`;