import React from 'react';
import { COLORS } from "../../../Theme";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";

type NavButtonProps = {
    color?: string;
    textColor?: string;
    children: string;
    icon?: React.ReactNode;
    route: string;
    onClick?: () => void;
};

export default function NavButton({
  color = COLORS.PRIMARY,
  children,
  route,
  onClick,
  icon,
  textColor = "#fff",
}: NavButtonProps) {
  const { hash, pathname, search } = useLocation();
  const navigate = useNavigate();
  return (
    <ButtonWrapper
      onClick={() => onClick ? onClick() : navigate(route)}
      color={color}
      style={{ cursor: "pointer" }}
      active={pathname === route}
    >
      {icon && icon}
      <ButtonText color={textColor}>{children}</ButtonText>
    </ButtonWrapper>
  );
}

const ButtonWrapper = styled.div<{active: boolean}>`
  background-color: ${(props) => props.active ? "grey" : "rgba(0,0,0,0)"};
  z-index: 1000;
  transition: background-color 0.3s ease;  /* Smooth transition for the hover effect */
  display: flex;
  align-items: center;
  width: 110%;
  cursor: pointer;
  gap: 10px;
  padding: 3px;

  &:hover {
    background-color: #adadad;
    cursor: pointer;
  }
`;

const ButtonText = styled.div<{color: string}>`
    color: #fff;
`;
