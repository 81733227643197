import { getLead } from "../Lead/api";

const getInvoice = async (customer_id: string, invoice_id: string) => {
  try {
    const lead = await getLead(customer_id);
    const invoices = lead.invoices;
    console.log("invoices in api", invoices);
    return invoices.find((invoice) => invoice.internalid === invoice_id);
  } catch (error) {
    console.error("Error fetching invoice:", error);
    throw new Error("Failed to fetch invoice. Please try again later.");
  }
};

const getInvoices = async (customer_id: string) => {
  try {
    const lead = await getLead(customer_id);
    const invoices = lead.invoices;
    return invoices;
  } catch (error) {
    console.error("Error fetching invoices:", error);
    throw new Error("Failed to fetch invoices. Please try again later.");
  }
};

export { getInvoice, getInvoices };
