import { useState, useEffect } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import styled from "@emotion/styled";
import { COLORS } from "../../../../Theme";

type ConfirmModalProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    title: string;
    description: string;
    onConfirm: () => void;
    confirmText?: string;
    showCancel?: boolean;
    showHeader?: boolean;
};

export default function ConfirmModal({
  open,
  setOpen,
  title,
  description,
  onConfirm,
  confirmText="Confirm",
  showCancel=true,
  showHeader=true,
}: ConfirmModalProps) {
  const onConfirmHelper = () => {
    setOpen(false);
    onConfirm();
  };

  return (
    <Dialog onClose={() => setOpen(false)} open={open}>
      {showHeader && (
        <ModalHeader>
          <DialogTitle
            style={{ color: "#fff", fontWeight: "bold", fontSize: 18 }}
          >
            {title}
          </DialogTitle>
        </ModalHeader>
      )}
      <ModalContent>
        <Description>{description}</Description>
        <Button variant="contained" onClick={onConfirmHelper}>
          {confirmText}
        </Button>
        {showCancel && <Button onClick={() => setOpen(false)}>Cancel</Button>}
      </ModalContent>
    </Dialog>
  );
}

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 350px;
    min-height: 100px;
    padding: 1rem 3rem;
    padding-bottom: 3rem;
    align-items: center;
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: center;
    background-color: ${COLORS.PRIMARY}
`;

const Title = styled.div``;

const Description = styled.p`
    color: #33CCFF;
    font-size: 1rem;
    font-weight: bold;
`;