import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
// @ts-ignore
import {ReactComponent as MomentumLogo} from "../../../assets/momentum-logo-2.svg";
import { formatCurrency } from "../../../util";
import { BounceLoader } from "react-spinners";

type InvoicePageProps = {
    invoice: Invoice | undefined;
    isLoading: boolean;
};

export default function InvoicePage({invoice, isLoading}: InvoicePageProps) {


  if(isLoading) {
    return (
        <LoaderContainer>
          <BounceLoader color="#33CCFF" />
          <p style={{color: "#33CCFF"}}>Retrieving Invoice...</p>
        </LoaderContainer>
    );
  }


  if(!invoice) return null;

//   const invoice_items = invoice.items;

  return (
    <InvoiceContainer>
      <Header>
        <HeaderLeft>
          <TitleContainer>
            <MomentumLogo />
            <SubTitle>
              3096 Hamilton Blvd #2B, South Plainfield, NJ 07080
            </SubTitle>
            <SubTitleLink to="https://momentumsolar.com/">
              https://momentumsolar.com/
            </SubTitleLink>
            <SubTitle>(888) 666-3688</SubTitle>
          </TitleContainer>
          <BillToContainer>
            <BillToTitle>BILL TO:</BillToTitle>
            <BillToText>{invoice.financier_customer}</BillToText>
            <BillToText>ADDRESS_PLACEHOLDER</BillToText>
            <BillToText>EMAIL_PLACEHOLDER</BillToText>
            <BillToText>PHONE_PLACEHOLDER</BillToText>
          </BillToContainer>
        </HeaderLeft>
        <HeaderRight>
          <MomentumLogo />
          <LogoTextRow>
            <LogoText>
              <strong>Invoice No: </strong>
            </LogoText>
            <LogoText>#{invoice.internalid}</LogoText>
          </LogoTextRow>
          <LogoTextRow>
            <LogoText>
              <strong>Invoice Date: </strong>
            </LogoText>
            <LogoText>{invoice.date}</LogoText>
          </LogoTextRow>
          <LogoTextRow>
            <LogoText>
              <strong>Due Date: </strong>
            </LogoText>
            <LogoText>{invoice.due_date}</LogoText>
          </LogoTextRow>
        </HeaderRight>
      </Header>
      <BillingTable>
        <thead>
          <BillingTableRow>
            <BillingTableHeader>New Charges/Adjustments</BillingTableHeader>
            <BillingTableHeader>Amount</BillingTableHeader>
          </BillingTableRow>
        </thead>
        <tbody>
          {/* {invoice_items.map((item, idx) => {
                    return (
                        <BillingTableRow key={idx} color={idx % 2 !== 0 ? "#E8FBFF" : "#fff"}>
                            <BillingTableData>{item.description}</BillingTableData>
                            <BillingTableData>{formatCurrency(item.total)}</BillingTableData>
                        </BillingTableRow>
                    )
                })} */}
          <div>Pay Breakdown goes here</div>
        </tbody>
      </BillingTable>
      <BottomContainer>
        <BottomLeftContainer>
          <Notes>Thank you for your business!</Notes>
        </BottomLeftContainer>
        <BottomRightContainer>
          <SubTotalRow>
            <TotalSubTextLabel>SUBTOTAL</TotalSubTextLabel>
            <TotalSubTextAmount>
              {formatCurrency(parseInt(invoice.amount))}
            </TotalSubTextAmount>
          </SubTotalRow>
          <SubTotalRow>
            <TotalSubTextLabel>TAX</TotalSubTextLabel>
            <TotalSubTextAmount>TAX_PLACEHOLDER</TotalSubTextAmount>
          </SubTotalRow>
          <SubTotalRow>
            <TotalTextLabel>Balance Due </TotalTextLabel>
            <TotalTextAmount>
              {formatCurrency(parseInt(invoice.amount))}
            </TotalTextAmount>
          </SubTotalRow>
        </BottomRightContainer>
      </BottomContainer>
      <Footer>
        <TermsContainer>
          <TermsTitle>Terms & Conditions</TermsTitle>
          <TermsText>Payment is due within 30 days</TermsText>
        </TermsContainer>
      </Footer>
    </InvoiceContainer>
  );
}

const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;
`;

const InvoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #F5F5F5;
  padding: 2rem;
  border-radius: 0.1rem;
  background-color: white;
  width: 650px;
  height: 800px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const HeaderLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
`;

const HeaderRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 300px;
`;

const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

const LogoTextRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 200px;
`;

const LogoText = styled.p`
    font-size: 0.8em;
    color: #354669;
    margin: 0;
    padding: 0;
    text-align: left;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Title = styled.h1`
    font-size: 1.2em;
    text-align: center;
    color: #354669;
    // background-color: #f0f4f5;
    text-align: left;
    font-weight: normal;
    margin: 0;
`;

const SubTitle = styled.h2`
    font-size: 0.8em;
    text-align: center;
    color: #354669;
    // background-color: #f0f4f5;
    text-align: left;
    font-weight: normal;
    margin: 5px 0px;
`;

const SubTitleLink = styled(Link)`
    font-size: 0.8em;
    text-align: center;
    color: #354669;
    // background-color: #f0f4f5;
    text-align: left;
    margin: 0;
    font-weight: normal;
    text-decoration: none;
    border-bottom: 1px solid lightgrey;
`;

const BillToContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 5rem;
    padding-bottom: 3rem;
`;

const BillToTitle = styled.h3`
    font-size: 0.8em;
    text-align: center;
    color: #354669;
    // background-color: #f0f4f5;
    text-align: left;
    font-weight: normal;
    margin: 0;
    border-bottom: 1px solid lightgrey;
    width: 100%;
`;

const BillToText = styled.p`
    font-size: 0.8em;
    text-align: center;
    color: #354669;
    // background-color: #f0f4f5;
    text-align: left;
    font-weight: normal;
    margin: 0;
`;

const BillingTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const BillingTableHeader = styled.th`
    border: 1px solid lightgrey;
    padding: 0.5rem;
    font-size: 0.8em;
`;

const BillingTableRow = styled.tr`
    border: 1px solid lightgrey;
    padding: 0.5rem;
    background-color: ${props => props?.color};
`;

const BillingTableData = styled.td`
    border: 1px solid lightgrey;
    padding: 0.5rem;
    font-size: 0.8em;
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 2rem;
`;

const BottomLeftContainer = styled.div`
    padding-left: 5rem;
`;

const BottomRightContainer = styled.div`
    padding-right: 3rem;
`;

const TotalTextLabel = styled.p`
    font-size: 1em;
    margin: 0;
`;

const TotalTextAmount = styled.p`
    border-bottom: 1px solid black;
    font-size: 1em;
    margin: 0;
`;

const TotalSubTextLabel = styled.p`
    font-size: 0.8em;
    margin: 0;
`;

const TotalSubTextAmount = styled.p`
    border-bottom: 1px solid lightgrey;
    font-size: 0.8em;
    margin: 0;
`;

const SubTotalRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
`;

const Notes = styled.div`
    font-size: 0.8em;
`;

const Footer = styled.div`
    width: 100%;
    padding-top: 3rem;
`;

const TermsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const TermsTitle = styled.p`
    font-size: 0.7em;
    font-weight: bold;
    color: black;
    text-align: center;
    // background-color: #f0f4f5;
    text-align: left;
    border-bottom: 1px solid lightgrey;
    width: 300px;
    text-align: left;
    margin: 0;
`;

const TermsText = styled.p`
    font-size: 0.7em;
    text-align: center;
    color: #000;
    // background-color: #f0f4f5;
    text-align: left;
    font-weight: normal;
    margin: 0;
`;