import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import styled from "@emotion/styled";
import { Status } from "../../GlobalComponents";
import { formatCurrency } from "../../util";
import { Title } from "./components";


const columns: GridColDef[] = [
  { field: "transaction_id", headerName: "Transaction ID", width: 140 },
  { field: "internalid", headerName: "Internal ID", width: 140 },
  { field: "trandate", headerName: "Date", width: 160 },
  {
    field: "amount",
    headerName: "Amount",
    width: 160,
    // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <div>{formatCurrency(params.value) || "--"}</div>
    ),
  },
  { field: "type", headerName: "Transaction Type", width: 160 },
  {
    field: "payment_method",
    headerName: "Payment Method",
    width: 160,
    //@ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <div>{params.value || "NA"}</div>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      params.value ? <Status status={params.value.toLowerCase()} /> : <div>NA</div>
    ),
  },
];

type PaymentTransactionsProps = {  
    transactions: Transactions;
    onClick?: (invoice: Invoice) => void;
};

export default function PaymentTransactions({transactions, onClick}:PaymentTransactionsProps) {

  return (
    <div style={{ height: 400, width: "100%" }}>
      <Title>Payment Transactions</Title>
      {transactions.length === 0 ? (
        <NoteText>No Transactions Found</NoteText>
      ) : (
        <DataGrid
          rows={transactions}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection={false}
          getRowId={(row) => row.transaction_id}
        />
      )}
    </div>
  );
}

const NoteText = styled.div`
  font-size: 1rem;
  color: grey;
  font-style: italic;
`;
