import React from "react";
import styled from "@emotion/styled";
import { COLORS } from "../../../../Theme";
import { Loader } from "../../../../GlobalComponents";

type SavedSearchListProps = {
  savedSearches: SavedSearch[] | undefined;
  isLoading: boolean;
  onSearchClick: (search: SavedSearch) => void;
};

export default function SavedSearchesList({
  savedSearches,
  isLoading,
  onSearchClick,
}: SavedSearchListProps) {
  return (
    <Container>
      <HelperText>Select a Saved Search to edit assignees</HelperText>
      {isLoading ? (
        <div style={{paddingTop: 22}}>
          <Loader text="Loading Saved Searches" />
        </div>
      ) : (
        <SearchesContainer>
          {savedSearches?.map((search) => (
            <SearchItem key={search.id} onClick={() => onSearchClick(search)}>
              {search.readable_name}
            </SearchItem>
          ))}
        </SearchesContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  border-radius: 8px;
  padding: 15px;
  width: 100%;
`;

const HelperText = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${COLORS.GREY_MEDIUM2};
  width: 100%;
  text-align: left;
  padding-left: 20px;
  padding-top: 5px;
`;

const SearchesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 10px;
  max-height: 250px; /* Limit the maximum height and enable scrolling */
`;

const SearchItem = styled.div`
  background-color: ${COLORS.GREY_LIGHT};
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: ${COLORS.GREY_MEDIUM};
  font-size: 14px;
  font-weight: 600;

  &:hover {
    background-color: ${COLORS.GREY_MEDIUM};
  }
`;
