import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { Logout } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useMsal } from "@azure/msal-react";
import { useNavigate, useLocation } from "react-router-dom";
// @ts-ignore
import { ReactComponent as MomentumLogo } from "../../assets/momentum-logo-2.svg";
import { COLORS } from "../../Theme";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import { adminEmails } from "../../config";
import DevUrlSwitch from "./DevUrlSwitch";
import DevToolsModal from "../DevToolsModal";
import { useAuthSession } from "../../Auth";

type HeaderProps = {
  view?: string;
  children?: React.ReactNode;
  rightContent?: React.ReactNode;
};

export default function Header({ view, children, rightContent }:HeaderProps) {
  const location = useLocation();
  const { hash, pathname, search } = location;
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [devToolsOpen, setDevToolsOpen] = useState(false);
  const { email, isProxy, promptProxyLogin, clearProxyLogin, isSupport } = useAuthSession();
  // @ts-ignore
  const { name } = instance.getActiveAccount();

  return (
    <Wrapper>
      <div style={{ display: "flex", gap: 20 }}>
        {pathname !== "/" ? (
          <Button
            variant="outlined"
            size="small"
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
        ) : (
          <div style={{ width: 20 }} />
        )}
        <DevUrlSwitch />
        {rightContent}
      </div>
      <ProfileContainer>
        <Typography
          variant="subtitle2"
          sx={{ display: "inline", marginRight: "1em" }}
        >
          Signed in as {isProxy ? email : name}
        </Typography>
        {isSupport && (
          <Button
            endIcon={<Logout />}
            variant="outlined"
            size="small"
            onClick={promptProxyLogin}
          >
            Change Proxy Login
          </Button>
        )}
        {isProxy && (
          <Button
            endIcon={<Logout />}
            variant="outlined"
            size="small"
            onClick={clearProxyLogin}
          >
            Clear Proxy Login
          </Button>
        )}
        <Button
          endIcon={<Logout />}
          variant="outlined"
          size="small"
          onClick={() => instance.logout()}
        >
          Logout
        </Button>
      </ProfileContainer>
    </Wrapper>
  );
}

const Wrapper = styled.header`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem;
  gap: 1rem;
  justify-content: space-between;
  height: 35px;
  border-bottom: 1px solid #dedede;
`;

const ProfileContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding-right: 2rem;
`;