import React from "react";
import styled from "@emotion/styled";
import { COLORS } from "../../../Theme";
import { formatCurrency, formatDateString, countMissedPayments } from "../../../util";

type WidgetProps = {
  lead: Lead;
};

export default function Widgets({ lead }: WidgetProps) {

  const lastPaymentAmount = lead?.payments ? lead?.payments[lead?.payments?.length - 1]?.amount ?? 0 : 0;
  
  return (
    <WidgetsWrapper>
      <Column>
        <WidgetContainer>
          <WidgetHelperText>Amount Overdue:</WidgetHelperText>
          <WidgetText>
            {formatCurrency(lead.account_data.overduebalance)}
          </WidgetText>
        </WidgetContainer>
        <WidgetContainer>
          <WidgetHelperText>Days Overdue:</WidgetHelperText>
          <WidgetText>{lead.account_data.daysoverdue || "NA"}</WidgetText>
        </WidgetContainer>
        <WidgetContainer>
          <WidgetHelperText>Collections Status:</WidgetHelperText>
          <WidgetText>{lead.collections_information.collections_status || "NA"}</WidgetText>
        </WidgetContainer>
        <WidgetContainer>
          <WidgetHelperText>Missed Payments:</WidgetHelperText>
          <WidgetText>
            {countMissedPayments(lead.invoices)}
            (Custom Function that checks each invoice for payments)
          </WidgetText>
        </WidgetContainer>
        <WidgetContainer>
          <WidgetHelperText style={{ paddingRight: 20 }}>
            Reason for Missed Payment:
          </WidgetHelperText>
          <WidgetText>
            {lead.collections_information.reason_for_missed_payment || "NA"}
          </WidgetText>
        </WidgetContainer>
      </Column>
      <Column>
        <WidgetContainer>
          <WidgetHelperText>Time to Call:</WidgetHelperText>
          <WidgetText>{lead.collections_information.time_to_call || "NA"}</WidgetText>
        </WidgetContainer>
        <WidgetContainer>
          <WidgetHelperText>Last Successful Contact Date:</WidgetHelperText>
          <WidgetText>
            {formatDateString(
              lead.collections_information.successful_customer_contact_date,
            ) || "NA"}
          </WidgetText>
        </WidgetContainer>
        <WidgetContainer>
          <WidgetHelperText>Last Contact Attempted:</WidgetHelperText>
          <WidgetText>
            {formatDateString(
              lead.collections_information.last_contact_attempted,
            ) || "NA"}
          </WidgetText>
        </WidgetContainer>
        <WidgetContainer>
          <WidgetHelperText>Last Payment Amount:</WidgetHelperText>
          <WidgetText>
            {
            lastPaymentAmount ?
            formatCurrency(
              // lead.last_payment_amount
              lastPaymentAmount,
            ) : "NA"}
          </WidgetText>
        </WidgetContainer>
        <WidgetContainer>
          <WidgetHelperText>Last Payment Received:</WidgetHelperText>
          <WidgetText>
            {lead?.payments ? lead.payments[lead?.payments?.length - 1]?.trandate || "NA" : "NA"}
          </WidgetText>
        </WidgetContainer>
      </Column>
    </WidgetsWrapper>
  );
}

const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: flex-start;
  border-bottom: 1px dashed #ededed;
  margin: 5px;
  width: 100%;
  // border-radius: 5px;
  // max-width: 85%;
  overflow: hidden;
`;

const WidgetHelperText = styled.div`
  font-size: 0.8em;
  color: ${COLORS.PRIMARY};
  margin: 0;
  font-weight: bold;
`;

const WidgetText = styled.div`
  font-size: 14px;
  color: ${COLORS.GREY_MEDIUM};
  margin: 0;
  padding-left: 10px;
`;

const WidgetsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  gap: 10px;
  height: auto;
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 5px;
  padding: 5px;
`;

const VerticalSpacer = styled.div`
  height: 20px;
  width: 1px;
  background-color: ${COLORS.GREY_LIGHT};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  height: 100%;
`;