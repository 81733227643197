import React, { useState, useContext } from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import { ENV } from "../../config";
import axios from "axios";
import { Context } from "../../Context";

export default function DevUrlSwitch() {
  const { env, setEnv } = useContext(Context);
  console.log("ENV", ENV);

  return (
    <FormControl component="fieldset" variant="standard">
      <div style={{display: "flex", alignItems: "center", gap: 5}}>
        <FormLabel component="legend">Environment</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={env === "PROD"}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  axios.defaults.baseURL = !e.target.checked
                    ? "https://api-dev.momentumsolar.io"
                    : "https://api.momentumsolar.io";
                  setEnv(isChecked ? "PROD" : "DEV");
                }}
                name="base-url"
              />
            }
            label={env === "PROD" ? "PROD" : "Sandbox"}
          />
        </FormGroup>
      </div>
    </FormControl>
  );
}
