import React from "react";
import styled from "@emotion/styled";

type TabsProps = {
  tabs: string[];
  setTab: (tab: string) => void;
  tab: string;
};

export default function Tabs({ tabs, setTab, tab }: TabsProps) {
  console.log("TABS", tabs, "TAB", tab);

  return (
    <div className="tabs">
      {tabs.map((buttonTab) => (
        <TabButton
          key={buttonTab}
          className={`tab ${buttonTab === tab ? "active" : ""}`}
          onClick={() => setTab(buttonTab)}
        >
          {buttonTab}
        </TabButton>
      ))}
    </div>
  );
}

const TabButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #35466933;
  color: #354669;
  text-transform: capitalize;
  &.active {
    border-bottom: 1px solid #354669;
    color: #354669;
  }
`;
