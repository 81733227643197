import React, { useState } from "react";
import { PageView } from "../../GlobalComponents";
import styled from "@emotion/styled";
import Assignments from "./Assignments";

export default function Admin() {

  return (
    <PageView>
        <Assignments />
    </PageView>
  );
}

