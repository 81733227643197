import React, { useState } from 'react';
import { Button, Select, SelectChangeEvent, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Modal, Loader } from '../../GlobalComponents';
import TEAM_MEMBERS from "../../Views/Admin/team_members.json";
import { unlockLead } from './api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { COLORS } from '../../Theme';
import { useAuthSession } from '../../Auth';


type DevToolsModalProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    lead?: Lead;
    methods: {
      onCopyCustomerId?: () => void;
      onUnlockCurrentLead?: () => void;
    }
};

export default function DevToolsModal({open, setOpen, lead, methods}: DevToolsModalProps) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [selectedTeamMember, setSelectedTeamMember] = useState<string | undefined>();
  const { email, onSelectProxyEmail } = useAuthSession();

  const { mutate: unlockLeadMutation, isPending: unlockLeadLoading } =
    useMutation({
      mutationFn: () => unlockLead(email, !lead ? "" : lead.profile.customerID),
      onSuccess: () => {
          window.alert("Lead unlocked successfully");
          navigate(`/`);
          queryClient.removeQueries({queryKey: ["lead"]});
      },
    });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      showXButton={false}
      width={"400px"}
      height={"400px"}
    >
      {unlockLeadLoading ? (
        <Loader />
      ) : (
        <Content>
          <h1 style={{ color: COLORS.PRIMARY }}>Dev Tools</h1>
          {lead && (
            <div style={{ marginBottom: 20 }}>
              <HelperText>Customer ID: {lead.profile.customerID}</HelperText>
              <HelperText>Agent: {email}</HelperText>
            </div>
          )}
          <ActionsContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                marginBottom: 20,
              }}
            >
              {methods?.onCopyCustomerId && (
                <Button variant="outlined" onClick={methods.onCopyCustomerId}>
                  Copy Customer ID
                </Button>
              )}
              {methods?.onUnlockCurrentLead && (
                <Button variant="outlined" onClick={() => unlockLeadMutation()}>
                  Unlock Current Lead
                </Button>
              )}
            </div>
            <FormControl>
              <InputLabel id="select-proxy-team-member-label">
                Select Proxy Team Member
              </InputLabel>
              <Select
                labelId="select-proxy-team-member-label"
                label="Select Proxy Team Member"
                value={selectedTeamMember}
                onChange={(e: SelectChangeEvent) => {
                  setSelectedTeamMember(e.target.value);
                  //@ts-ignore
                  onSelectProxyEmail(e.target.value);
                  setOpen(false);
                }}
                sx={{ width: 300, marginBottom: 2 }}
              >
                {TEAM_MEMBERS.map((teamMember: string) => (
                  <MenuItem key={teamMember} value={teamMember}>
                    {teamMember}
                  </MenuItem>
                ))}
              </Select>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
              >
                Close
              </Button>
            </FormControl>
          </ActionsContainer>
        </Content>
      )}
    </Modal>
  );
}


const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1em;
    gap: 10px;
    height: 50%;
`;

const HelperText = styled.div`
    color: ${COLORS.PRIMARY};
`;