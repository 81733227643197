const formatCurrency = (value: number | string) => {
  if(value === "") return;

  // Check if the input is a valid number
  if (typeof value !== "number" && isNaN(Number(value))) {
    return undefined; // Return undefined if input is not a valid number
  }

  // Convert the input value to a number if it's a string
  const numericValue = typeof value === "string" ? parseFloat(value) : value;

  // Format the number as currency
  const formatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(numericValue);

  return formatted;
};

export default formatCurrency;
