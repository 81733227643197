import React from "react";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { Microsoft } from "@mui/icons-material";

type LoginPageProps = {
    onLogin: () => void;
};

export default function LoginPage({ onLogin }: LoginPageProps) {
  return (
    <Wrapper>
      <Typography variant="h4">
        Welcome to the MS Collections Portal
      </Typography>
      <Typography variant="body1">Please login to continue</Typography>
      <Button onClick={onLogin} variant="contained" startIcon={<Microsoft />}>
        Login
      </Button>
    </Wrapper>
  );
}


const Wrapper = styled.main`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    gap: 2em;
`;



