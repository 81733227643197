import React from "react";
import styled from "@emotion/styled";
import Skeleton from '@mui/material/Skeleton';

type InvoicesScrollListProps = {
    invoices: Invoice[];
    onClick: (invoice_number: string) => void;
    isLoading: boolean;
};

export default function InvoicesScrollList({invoices, onClick, isLoading}:InvoicesScrollListProps) {

  if(!invoices) return <Skeletons/>;

  return (
    <Container>
        {invoices.map((invoice) => (
            <InvoiceCard key={invoice.internalid} onClick={() => onClick(invoice.internalid)}>
                <div>Invoice No: {invoice.internalid}</div>
                <div>Date: {invoice.date}</div>
                <div>Amount: {invoice.amount}</div>
                <StatusText className={invoice.status.toLowerCase()}>{invoice.status}</StatusText>
            </InvoiceCard>
        ))}
    </Container>
  );
}

const skeletonAmount = 3;

const Skeletons = () => {
    return (
        <Container>
            {Array(skeletonAmount).fill(0).map((_, index) => (
                <InvoiceCard key={index}>
                    <Skeleton variant="text" width="100%" height="20px"/>
                    <Skeleton variant="text" width="100%" height="20px"/>
                    <Skeleton variant="text" width="100%" height="20px"/>
                    <Skeleton variant="text" width="100%" height="20px"/>
                </InvoiceCard>
            ))}
        </Container>
    )
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgrey;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 20vw;
  height: 100vh;
`;

const InvoiceCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid #F5F5F5;
    border-radius: 0.5rem;
    width: 100%;
    height: 100px;
    margin: 5px 0px;
    background-color: #fff;
    cursor: pointer;
    padding: 0.5rem;
`;

const InvoiceText = styled.div`
    color: ${(props) => props.color};
    height: 50px;
    display: flex;
    align-items: center;
`;

const StatusText = styled.div`
    padding: 0.2rem;
    border-radius: 0.2rem;
    &.overdue {
        background-color: #f8d7da;
        color: #721c24;
    }
    &.paid {
        background-color: #d4edda;
        color: #155724;
    }
    &.pending {
        background-color: #fff3cd;
        color: #856404;
    }
`;