import React from "react";
import { Person as MUIPersonIcon }from "@mui/icons-material";
import styled from "@emotion/styled";

type PersonIconProps = {
    person: TeamMember;
    size?: "small" | "medium" | "large";
    onClick?: () => void;
};


export default function PersonIcon({ person, size="medium", onClick }: PersonIconProps) {

  return (
    <IconWrapper backgroundColor={person.color as string} size={size} onClick={onClick}>
      <IconText>{person.email[0]}</IconText>
    </IconWrapper>
  );
}

const IconWrapper = styled.div<{
  backgroundColor: string;
  size: "small" | "medium" | "large";
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) =>
    props.size === "small"
      ? "30px"
      : props.size === "medium"
        ? "40px"
        : "50px"};
  height: ${(props) =>
    props.size === "small"
      ? "30px"
      : props.size === "medium"
        ? "40px"
        : "50px"};
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
  color: #000;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const IconText = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-transform: capitalize;
    &:hover {
        color: #000;
    }
`;