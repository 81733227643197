import React, { useState, createContext, useContext, useEffect } from "react";
import { ENV, devEmails } from "../../config";
import { useMsal } from "@azure/msal-react";

const AuthContext = createContext({} as AuthContextProps);

type AuthContextProps = {
  email: string;
  setEmail: (arg: string) => void;
  isProxy: string | boolean;
  promptProxyLogin: () => void;
  clearProxyLogin: () => void;
  isSupport: boolean;
  onSelectProxyEmail: (arg: string) => void;
};

const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [email, setEmail] = useState("");
  const { instance } = useMsal();
  // @ts-ignore
  const activeAccount = instance?.getActiveAccount();
  const userEmail = activeAccount?.username || "";

  useEffect(() => {
    if(userEmail) {
        setEmail(userEmail);
    }
  }, [userEmail]);

  const promptProxyLogin = () => {
    if (userEmail && devEmails.includes(userEmail)) {
      const userChoice = window.confirm(
        `Your detected email is ${email || userEmail}. Would you like to use this email? Click "Cancel" to enter a different email.`,
      );
  
      if (userChoice) {
        setEmail(email || userEmail);
      } else {
        let proxyEmail = prompt("Please enter a proxy login email:");
        if (proxyEmail) {
          // Append @momentumsolar.com if it's not already present
          if (!proxyEmail.endsWith("@momentumsolar.com")) {
            proxyEmail += "@momentumsolar.com";
          }
          setEmail(proxyEmail);
        }
      }
    }
  };

  const clearProxyLogin = () => {
    setEmail(userEmail);
  };

  const isProxy = email && userEmail && email !== userEmail;
  const isSupport = devEmails.includes(userEmail);
  const onSelectProxyEmail = (email: string) => {
    if(email) {
        setEmail(email);
    }
  };

  return (
    <AuthContext.Provider value={{ email, setEmail, isProxy, promptProxyLogin, clearProxyLogin, isSupport, onSelectProxyEmail }}>
      {children}
    </AuthContext.Provider>
  );
};

// This hook can be used to access the user info.
function useAuthSession() {
  const value = useContext(AuthContext);

  if (process.env.NODE_ENV !== "production") {
    if (!value) {
      throw new Error("useAuthSession must be wrapped in a <AuthProvider />");
    }
  }
  return value;
}

export { AuthContext, AuthProvider, useAuthSession };
