const buildSuboutcomes = (subOutcomeData: OutcomeOption[] | undefined): any => {
    if(!subOutcomeData) return [];

    const contact_sub_outcomes = subOutcomeData.filter((opt: OutcomeOption) => {
        if(sub_outcomes.contact.includes(opt.txt)) {
            return opt;
        }
    })

    const no_contact_sub_outcomes = subOutcomeData.filter((opt: OutcomeOption) => {
        if(sub_outcomes.no_contact.includes(opt.txt)) {
            return opt;
        }
    })

    return {
        contact: contact_sub_outcomes,
        no_contact: no_contact_sub_outcomes
    }
};

const sub_outcomes = {
  contact: [
    "Promise To Pay",
    "Payment",
    "Follow Up",
],
no_contact: [
    "Left Voicemail",
    "Disconnected",
    "No Voicemail",
    "Wrong Number",
    "Customer Hung Up",
  ],
};

export {
    sub_outcomes,
    buildSuboutcomes,
}