import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function NotAuthorized() {
  const navigate = useNavigate();
  return (
    <div>
        <h1>Not Authorized</h1>
        <p>You are not authorized to view this page.</p>
        <Button variant="contained" color="primary" href="/">
            Go Home
        </Button>
    </div>
  );
}
