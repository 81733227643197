import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import styled from "@emotion/styled";
import { COLORS } from "../../../../Theme";

type SearchBarProps = {
    placeholder: string;
    onChange: (value: string) => void;
};

const SearchBar = ({ placeholder, onChange }: SearchBarProps) => {
  return (
    <SearchContainer>
      <StyledSearchIcon />
      <SearchInput
        type="text"
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
    </SearchContainer>
  );
};

export default SearchBar;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 400px;
  border: 1px solid ${COLORS.GREY_MEDIUM3};
  border-radius: 5px;
  padding: 5px 15px;
  background-color: #fff;
`;

const StyledSearchIcon = styled(SearchIcon)`
  color: ${COLORS.GREY_MEDIUM2};
  font-size: 1.2rem;
  margin-right: 10px;
`;

const SearchInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: 1rem;
  color: #333;

  &::placeholder {
    color: ${COLORS.GREY_MEDIUM2};
    font-size: 14px;
  }
`;