import axios from "axios";

export function initAxiosDefaultValues(
  environment = "PROD",
) {

  // axios.defaults.headers.app_version = appData.expo.version;
  axios.defaults.baseURL =
    environment === "DEV"
      ? "https://api-dev.momentumsolar.io"
      : "https://api.momentumsolar.io";
}
