const parseMS = (ms: string): string => {
  if (!ms) {
    return "";
  }

  const stringWithNumbersOnly = String(ms).replace(/\D/g, "");
  return stringWithNumbersOnly;
};

export default parseMS;
