import React, { useState, createContext } from "react";
import { ENV } from "./config";

const Context = createContext({} as ContextProps);

type ContextProps = {
    env: "DEV" | "PROD";
    setEnv: (arg: "DEV" | "PROD") => void;
};

const ContextProvider = ({ children }: { children: JSX.Element}) => {
    const [env, setEnv] = useState<"DEV" | "PROD">(ENV);

    return (
        <Context.Provider value={{ env, setEnv }}>
            {children}
        </Context.Provider>
    );
};

export { Context, ContextProvider };
