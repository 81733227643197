import React from "react";
import styled from "@emotion/styled";
import SavedSearchesList from "./SavedSearchesList";
import { COLORS } from "../../../../Theme";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";

type ToolbarProps = {
  savedSearches: SavedSearch[] | undefined;
  isLoading: boolean;
  onSearchClick: (search: SavedSearch) => void;
};

export default function Toolbar({
  savedSearches,
  isLoading,
  onSearchClick,
}: ToolbarProps) {
  return (
    <Wrapper>
      <ToolbarHeader>
        <TitleWrapper>
            <IconWrapper>
            <SavedSearchIcon sx={{color: "#fff", width: 30, height: 30}} />
            </IconWrapper>
            <TitleText>Saved Searches</TitleText>
        </TitleWrapper>
      </ToolbarHeader>
      <SavedSearchesList
        savedSearches={savedSearches}
        isLoading={isLoading}
        onSearchClick={onSearchClick}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 325px;
  height: 100%;
  align-items: center;
  background-color: #fff;
  border-right: 2px solid ${COLORS.GREY_MEDIUM3};
  // cursor: grab;
  touch-action: none;
`;

const ToolbarHeader = styled.div`
  // display: flex;
  // justify-content: space-between;
  border-bottom: 2px solid ${COLORS.GREY_MEDIUM3};
  // padding: 1rem;
  width: 100%;
  height: 75px;
  background-color: #f5f9fa;
`;

const IconWrapper = styled.div`
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to top right, #336699, #bbd4ed);
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem;
`;

const TitleText = styled.div`
    color: ${COLORS.PRIMARY};
    font-size: 18px;
    // width: 20%;
    cursor: pointer;
    font-weight: bold;
    padding-left: 10px;
`;