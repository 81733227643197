import React from "react";
import styled from "@emotion/styled";
import Note from "./Note";
import { StyledModal } from "../../../GlobalComponents";
import { Title } from "../components";

type NotesProps = {
  notes: Note[];
};

export default function Notes({ notes }: NotesProps) {
  const [noteModalOpen, setNoteModalOpen] = React.useState(false);
  const [selectedNote, setSelectedNote] = React.useState<Note | null>(null);

  console.log("notes", notes);

  const onNoteClick = (note: Note) => {
    setSelectedNote({
      ...note,
      date: note.date,
    });
    setNoteModalOpen(true);
  };

  if(notes.length === 0) return <NoteText>No notes to display</NoteText>;

  return (
    <div>
      <Title>Notes</Title>
      <NotesContainer>
        {notes.map((note, idx) => (
          <Note key={idx} note={note} onNoteClick={onNoteClick} />
        ))}
        <StyledModal
          open={noteModalOpen}
          setOpen={setNoteModalOpen}
          title={selectedNote?.date?.toString() ?? "Note"}
          text={selectedNote?.description}
        />
      </NotesContainer>
    </div>
  );
}

const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgrey;
  padding: 1rem 0;
  border-radius: 0.2rem;
  width: 98%;
  height: 300px;
  overflow-y: scroll;
`;

const NoteText = styled.div`
  font-size: 1rem;
  color: grey;
  font-style: italic;
`;
