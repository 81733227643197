import React from "react";
import styled from "@emotion/styled";

// icons
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import LocationCityIcon from '@mui/icons-material/LocationCity';

// components
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { COLORS } from "../../../Theme";
import { StyledModal } from "../../../GlobalComponents";
import Widgets from "./Widgets";
import { formatPhone, parseMS } from "../../../util";
import { Link } from "react-router-dom";

// util
import { formatCurrency } from "../../../util";

type LeadInfoTableProps = {
    lead: Lead;
};

export default function LeadInfoTable({lead}:LeadInfoTableProps) {
  const [arbModalOpen, setARBModalOpen] = React.useState(false);
  const [achModalOpen, setACHModalOpen] = React.useState(false);

  const hasARB = lead.collections_information.collections_pay_plan_setup === "1"

  return (
    <Table>
      <TableRightContainer>
        <CustomerInfoWrapper>
          <LeadInfoCell>
            <LeadInfoText style={{ fontWeight: "bold" }}>
              MS#{parseMS(lead.profile["ms_#"])}
            </LeadInfoText>
          </LeadInfoCell>
          <LeadInfoCell>
            <PersonIcon sx={{ color: "#336699" }} />
            <LeadInfoText style={{ textTransform: "capitalize" }}>
              {lead.profile?.name.toLowerCase()}
            </LeadInfoText>
          </LeadInfoCell>
          <LeadInfoCell>
            <HomeIcon sx={{ color: "#336699" }} />
            <LeadInfoText style={{ textTransform: "capitalize" }}>
              {lead.profile.install_address_1.toLowerCase()}
            </LeadInfoText>
          </LeadInfoCell>
          <LeadInfoCell>
            <LocationCityIcon sx={{ color: "#336699" }} />
            <LeadInfoText style={{ textTransform: "capitalize" }}>
              {lead.profile.install_city.toLowerCase()}
            </LeadInfoText>
          </LeadInfoCell>
          <LeadInfoCell>
            <strong style={{ paddingTop: 5 }}>State: </strong>
            <LeadInfoText>{lead.profile.install_state}</LeadInfoText>
          </LeadInfoCell>
          <LeadInfoCell>
            <strong style={{ paddingTop: 5 }}>Zip: </strong>
            <LeadInfoText>{lead.profile.install_zip_code}</LeadInfoText>
          </LeadInfoCell>
          <LeadInfoCell>
            <Button
              variant="outlined"
              component={Link}
              to={`tel:${lead.profile.phone}`}
              sx={{height: 50}}
            >
              <PhoneIcon sx={{ color: "#336699" }} />
              <LeadInfoText>
                {formatPhone(lead.profile.phone)}
              </LeadInfoText>
            </Button>
          </LeadInfoCell>
          <LeadInfoCell>
          <Button
              variant="outlined"
              component={Link}
              to={`mailto:${lead.profile.email}`}
              sx={{height: 50}}
            >
            <EmailIcon sx={{ color: "#336699" }} />
            <LeadInfoText>
                {lead.profile.email.toLowerCase()}
            </LeadInfoText>
            </Button>
          </LeadInfoCell>
        </CustomerInfoWrapper>
        <div>
          {lead?.collections_information?.ach_setup ? (
            <Tooltip title="Customer is on automatic payments." arrow>
              <ACHContainer onClick={() => setACHModalOpen(true)}>
                <ACHText>ACH Active</ACHText>
              </ACHContainer>
            </Tooltip>
          ) : (
            <Tooltip title="Customer is not on automatic payments." arrow>
              <InactiveContainer>
                <InactiveText>ACH Inactive</InactiveText>
              </InactiveContainer>
            </Tooltip>
          )}
          {hasARB ? (
            <Tooltip title="Customer is on a payment plan." arrow>
              <ARBContainer onClick={() => setARBModalOpen(true)}>
                <ARBText>ARB Active</ARBText>
              </ARBContainer>
            </Tooltip>
          ) : (
            <Tooltip title="Customer is not on a payment plan." arrow>
              <InactiveContainer>
                <InactiveText>ARB Inactive</InactiveText>
              </InactiveContainer>
            </Tooltip>
          )}
          {lead?.collections_information?.spanish_speaking && (
            <Tooltip title="Customer speaks Spanish." arrow>
              <InactiveContainer>
                <InactiveText>Spanish Speaking</InactiveText>
              </InactiveContainer>
            </Tooltip>
          )}
        </div>
      </TableRightContainer>
      <Widgets lead={lead} />
      <StyledModal
        open={arbModalOpen}
        setOpen={setARBModalOpen}
        title={"ARB: Payment Plan"}
        headerColor={COLORS.ORANGE_LIGHT}
      >
        <ModalContent>
          <ModalTable>
            <ModalTableRow>
              <ModalTableCell
                backgroundColor={COLORS.GREY_LIGHT}
                textColor={COLORS.GREY}
              >
                <strong>Start Date</strong>
              </ModalTableCell>
              <ModalTableCell
                backgroundColor={COLORS.GREY_LIGHT}
                textColor={COLORS.GREY}
              >
                <strong>Amount</strong>
              </ModalTableCell>
              <ModalTableCell
                backgroundColor={COLORS.GREY_LIGHT}
                textColor={COLORS.GREY}
              >
                <strong>Frequency</strong>
              </ModalTableCell>
            </ModalTableRow>
            <ModalTableRow>
              <ModalTableCell backgroundColor="#fff" textColor={"black"}>
                {lead.collections_information
                  .timeframe_for_payment_plan_start || "NA"}
              </ModalTableCell>
              <ModalTableCell backgroundColor="#fff" textColor={"black"}>
                {!lead.collections_information.payment_plan_amount
                  ? "NA"
                  : formatCurrency(
                      parseInt(
                        lead.collections_information.payment_plan_amount,
                      ),
                    )}
              </ModalTableCell>
              <ModalTableCell backgroundColor="#fff" textColor={"black"}>
                {lead.collections_information.payment_plan_frequency || "NA"}
              </ModalTableCell>
            </ModalTableRow>
          </ModalTable>
        </ModalContent>
      </StyledModal>
      <StyledModal
        open={achModalOpen}
        setOpen={setACHModalOpen}
        title={"ACH"}
        headerColor={COLORS.GREEN_LIGHT}
      >
        <ModalContent>
          <ModalText>
            Automatic Payment Amount: ACH_Payment_Amount_Placeholder
            {/* {formatCurrency(lead.ach_info?.amount ?? 0)} */}
          </ModalText>
        </ModalContent>
      </StyledModal>
    </Table>
  );
}

const Table = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 20px;
`;

const LeadInfoCell = styled.div`
  color: ${(props) => props.color};
  height: 50px;
  display: flex;
  align-items: center;
`;

const LeadInfoText = styled.p`
  color: ${COLORS.PRIMARY};
  padding-left: 7px;
  font-size: 1em;
`;

const TableRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #ededed;
  width: 96%;
  border-radius: 5px;
  padding: 12px;
`;
  
const CustomerInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const ACHContainer = styled.div`
  border: 1px solid ${COLORS.GREEN};
  border-radius: 5px;
  background-color: ${COLORS.GREEN_LIGHT};
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  height: 30px;
  cursor: pointer;
`;

const ACHText = styled.p`
  color: ${COLORS.GREEN};
  font-size: 0.8em;
  margin: 0;
  padding: 0;
`;

const ARBContainer = styled.div`
  border: 1px solid ${COLORS.ORANGE};
  border-radius: 5px;
  background-color: ${COLORS.ORANGE_LIGHT};
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  cursor: pointer;
  margin-bottom: 15px;
`;

const ARBText = styled.p`
  color: ${COLORS.ORANGE_DARK};
  font-size: 0.8em;
  margin: 0;
  padding: 0;
`;

const InactiveContainer = styled.div`
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: #f0f4f5;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  cursor: pointer;
  margin-bottom: 15px;
`;

const InactiveText = styled.p`
  color: grey;
  font-size: 0.8em;
  margin: 0;
  padding: 0;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 425px;
    height: 125px;
    align-items: center;
    border-radius: 0.5rem;
    position: relative;
    background-color: #fff;
`;

const ModalText = styled.p`
    font-size: 0.9rem;
    color: #354669;
    margin: 0;
    text-transform: capitalize;
    font-weight: normal;
    width: 100%;
    text-align: left;
    padding-left: 40%;
    line-height: 2;
`;

const ModalTable = styled.div`
  width: 80%;
`;

const ModalTableRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    border: 1px solid ${COLORS.GREY};
    // border-radius: 0.5rem;
`;

const ModalTableCell = styled.div<{ backgroundColor: string; textColor: string }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.textColor};
    height: 100%;
    text-transform: capitalize;
`;