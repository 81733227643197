import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Login from "../Login";
import axios from "axios";

function PrivateRoute() {
  const { instance, accounts, inProgress } = useMsal();

  const getIdToken = async () => {
    if(inProgress === "none") {
      const request = {
        scopes: ["openid"],
        account: accounts[0],
      };
      const response = await instance.acquireTokenSilent(request);
      axios.defaults.headers.common["Authorization"] = `Basic ${response.accessToken}`;
      // console.log("token", response.accessToken);
      return response.accessToken;
    }
  };

  useEffect(() => {
    if (accounts.length > 0) {
        getIdToken();
    }
  }, []);

  if (accounts.length > 0) {
    return <Outlet />;
  }

  return <Login inProgress={inProgress} />;
}

export default PrivateRoute;
