const adminEmails = [
  "kpower@momentumsolar.com",
  "christopher.foy@momentumsolar.com",
  "mbharrat@momentumsolar.com",
  "dirwin@momentumsolar.com",
  "brett.sodie@momentumsolar.com",
  "cburkhardt@momentumsolar.com",
  "cburt@momentumsolar.com"
];

const devEmails = [
  "christopher.foy@momentumsolar.com",
  "mbharrat@momentumsolar.com",
  "dirwin@momentumsolar.com",
  "brett.sodie@momentumsolar.com",
  "cburkhardt@momentumsolar.com",
  "cburt@momentumsolar.com"
];

const ENV = "PROD";

export {
    adminEmails,
    devEmails,
    ENV,
}