import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Status } from "../../GlobalComponents";
import { formatCurrency } from "../../util";
import styled from "@emotion/styled";
import { Title } from "./components";

const columns: GridColDef[] = [
  {
    field: "invoice_#", headerName: "Invoice #", width: 120,
  },
  { field: "internalid", headerName: "Internal ID", width: 140 },
  { field: "date", headerName: "Date", width: 160 },
  {
    field: "amount",
    headerName: "Amount",
    width: 140,
    // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <div>{formatCurrency(params.value)}</div>
    ),
  },
  { field: "invoice_type", headerName: "Type", width: 120 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    // @ts-ignore
    renderCell: (params: GridValueGetterParams) => (
      <Status status={params.value} />
    ),
  },
  { field: "memo", headerName: "Memo", width: 400 },
];

type InvoicesListProps = {  
    invoices: Invoice[];
    oppnum: string;
    onClick: (invoice: Invoice) => void;
};

export default function InvoicesList({invoices, oppnum, onClick}:InvoicesListProps) {

  const onRowClick = (params: any, event: any) => {
    console.log(params.row.invoice_number);
    const invoice = invoices.find((invoice) => invoice.internalid === params.row.internalid);
    onClick(invoice as Invoice);
  };

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Title>Invoices</Title>
      {
        invoices.length === 0 ? <NoteText>No invoices found</NoteText> :
        <DataGrid
        rows={[...invoices].reverse()}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection={false}
        onRowClick={onRowClick}
        getRowId={(row) => row.internalid}
        sx={{ '& .MuiDataGrid-row': { cursor: 'pointer' } }}
      />}
    </div>
  );
}

const NoteText = styled.div`
  font-size: 1rem;
  color: grey;
  font-style: italic;
`;
