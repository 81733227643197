import React, { useState } from "react";
import styled from "@emotion/styled";
import Status from "../Status";
import { COLORS } from "../../Theme";
import { formatCurrency } from "../../util";

type SortObject = {
  key: keyof Payment | null;
  ascending: boolean;
};

export default function Transactions({ invoice }: { invoice: Invoice }) {
  const transactions = invoice?.payments || [];
  const [sortOrder, setSortOrder] = useState<SortObject>({
    key: null,
    ascending: true,
  });

  const handleSort = (key: keyof Payment) => {
    const isAscending = sortOrder.key === key ? !sortOrder.ascending : true;
    setSortOrder({ key, ascending: isAscending });
  };

  const getValue = (item: Payment, key: keyof Payment): any => {
    // Handle different data types for sorting keys
    switch (key) {
      case "trandate":
        return new Date(item[key]);
      case "amount":
        return parseFloat(item[key]);
      default:
        return item[key];
    }
  };

  const sortedTransactions = transactions.slice().sort((a, b) => {
    if (sortOrder.key) {
      const aValue = getValue(a, sortOrder.key);
      const bValue = getValue(b, sortOrder.key);
      if (aValue < bValue) return sortOrder.ascending ? -1 : 1;
      if (aValue > bValue) return sortOrder.ascending ? 1 : -1;
      return 0;
    }
    return 0;
  });


  return (
    <Container>
      <Title>Transactions</Title>
      <TransactionRow>
        <HeaderText style={{cursor: "auto"}}>
          Tran ID
        </HeaderText>
        <HeaderText onClick={() => handleSort("trandate")}>Date</HeaderText>
        <HeaderText onClick={() => handleSort("amount")}>Amount</HeaderText>
        <HeaderText
          style={{ paddingRight: 10 }}
          onClick={() => handleSort("payment_method")}
        >
          Payment Method
        </HeaderText>
        <HeaderText style={{ paddingRight: 20 }}>Status</HeaderText>
      </TransactionRow>
      {!transactions.length ? (
        <div style={{ marginTop: 20 }}>No transactions found</div>
      ) : (
        <TransactionsScrollViewWrapper>
          <TransactionsScrollView>
            {sortedTransactions.map((payment, idx) => (
              <TransactionRow
                key={payment.internalid}
                style={{ backgroundColor: idx % 2 !== 0 ? "#fcfcfc" : "#fff" }}
              >
                <Text>{payment.transaction_id}</Text>
                <Text>{payment.trandate}</Text>
                <Text>{formatCurrency(payment.amount) || "--"}</Text>
                <Text>{payment.payment_method || "NA"}</Text>
                <Text>{payment.status || "NA"}</Text>
              </TransactionRow>
            ))}
          </TransactionsScrollView>
        </TransactionsScrollViewWrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  height: 298px;
`;

const TransactionsScrollViewWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const TransactionsScrollView = styled.div`
    overflow-y: scroll;
    height: 100%;
`;

const TransactionRow = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dotted lightgrey;
    padding: 1rem;
    // border-radius: 0.5rem;
    width: 90%;
    padding-left: 40px;
`;

const HeaderText = styled.div`
    color: ${COLORS.PRIMARY};
    font-size: 12px;
    width: 20%;
    cursor: pointer;
`;

const Text = styled.div`
    font-size: 12px;
    width: 20%;
`;

const Title = styled.div`
    font-size: 16px;
    // font-weight: bold;
    margin-bottom: 1rem;
    color: lightgrey;
    // text-align: left;
    // width: 90%;
    font-style: italic;
`;